import React, { useState, useEffect } from "react";

import background_small from "../media/_stat/lib_bridge.jpg";
import background_medium from "../media/_stat/lib_bridge.jpg";
import background_large from "../media/_stat/lib_bridge.jpg";
import equation_1 from "../media/_stat/Equation_1.png";
import equation_2 from "../media/_stat/Equation_2.png";
import equation_3 from "../media/_stat/Equation_3.png";
import equation_4 from "../media/_stat/Equation_4.png";
import equation_5 from "../media/_stat/Equation_5.png";
import equation_6 from "../media/_stat/Equation_6.png";
import equation_7 from "../media/_stat/Equation_7.png";
import figure_0 from "../media/_stat/Figure_0.jpg";
import figure_1 from "../media/_stat/Figure_1.png";
import figure_2 from "../media/_stat/Figure_2.png";
import figure_3 from "../media/_stat/Figure_3.png";
import figure_4 from "../media/_stat/Figure_4.png";
import figure_5 from "../media/_stat/Figure_5.png";
import table_1 from "../media/_stat/Table_1.png";
import table_2a from "../media/_stat/Table_2a.png";
import table_2b from "../media/_stat/Table_2b.png";
import table_3 from "../media/_stat/Table_3.png";
import table_4 from "../media/_stat/Table_4.png";
import table_5 from "../media/_stat/Table_5.png";
import table_6 from "../media/_stat/Table_6.png";
import table_7 from "../media/_stat/Table_7.png";
import useWindowDimensions from "../redux/misc/windowDimensions";
import "../../node_modules/video-react/dist/video-react.css";
import ImageCaption from "../components/Image_Caption";
import PIndent from "../components/P_Indent";

import {
    Space,
    Typography,
    Divider,
    Row,
    Anchor,
    BackTop,
    Collapse,
} from "antd";
const { Link } = Anchor;
const { Panel } = Collapse;

const Page_Bio = (props) => {
    const {
        scr_height,
        scr_width,
        // scr_SMALL,
        scr_MEDIUM,
        scr_LARGE,
    } = useWindowDimensions();

    var bio_pic = background_small;
    var body_width = scr_width - props.tab_width_small;
    var body_tab_offset = props.tab_width_small;
    var justify_body_text = "start";

    if (scr_MEDIUM) {
        bio_pic = background_medium;
        body_width = scr_width - props.tab_width_medium;
        body_tab_offset = props.tab_width_medium;
    } else if (scr_LARGE) {
        bio_pic = background_large;
        body_width = scr_width - props.tab_width_large;
        body_tab_offset = props.tab_width_large;
        justify_body_text = "center";
    }

    const [targetOffset, setTargetOffset] = useState(undefined);
    useEffect(() => {
        setTargetOffset(window.innerHeight / 2);
    }, []);

    return (
        <div
            id="parent"
            style={{
                width: body_width,
            }}
            class={"Page_Stat"}
        >
            <div
                style={{
                    width: "100%",
                    height: scr_height * 0.88,
                    overflow: "visible",
                }}
            >
                <div
                    style={{
                        backgroundColor: "black",
                    }}
                >
                    <div
                        class="parallax animated fadeIn"
                        style={{
                            backgroundImage: `url(${bio_pic})`,
                            height: scr_height * 0.88,
                            width: body_width,
                            backgroundAttachment: "fixed",
                            backgroundPosition: "right top", // center
                            backgroundRepeat: "no-repeat",
                            backgroundSize: `${
                                body_width * 1.1
                            }px ${scr_height}px`,
                        }}
                    />
                </div>
                <Space
                    wrap={true}
                    align="start"
                    direction="vertical"
                    style={{
                        position: "absolute",
                        left: body_width * 0.1 + body_tab_offset,
                        top: scr_height * 0.28,
                    }}
                >
                    <p
                        class="parallax_text"
                        style={{
                            position: "relative",
                            opacity: 1,
                            textAlign: "left",
                            padding: "15px 15px 15px 30px",
                            borderRadius: "10px 50px 10px 50px",
                        }}
                    >
                        Just like in architecture,
                        <br />
                        we should build healthy business and personal
                        <br />
                        relationships that will grow in value as we age.
                    </p>
                </Space>
            </div>

            <Divider direction="horizontal" />

            <p class="header_paper TSPPPP TI0 TAC" style={{ width: "100%" }}>
                {" "}
                Liberty Bridge Structural Analysis:{" "}
            </p>

            <Row justify="end">
                <Anchor
                    targetOffset={targetOffset}
                    style={{
                        padding: 8,
                        textAlign: "start",
                        position: "relative",
                        right: 10,
                        width: "260px",
                    }}
                >
                    <Collapse bordered={true} defaultActiveKey={["1"]}>
                        <Panel header="Quick Link Table: " key="1" extra={""}>
                            <Space size="middle" direction="vertical">
                                <Link href="#I" title="I. Abstract" />
                                <Link
                                    href="#II"
                                    title="II. Conceptual Analysis"
                                />
                                <Link
                                    href="#III"
                                    title="III. Materials and Measurements"
                                />
                                <Link href="#IV" title="IV. Numeric Analysis" />
                                <Link href="#V" title="V. Safety Factor" />
                                <Link href="#REF" title="References" />
                            </Space>
                        </Panel>
                    </Collapse>
                </Anchor>
            </Row>

            <Row justify={justify_body_text}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        padding: "20px 20px 20px 0px",
                        maxWidth: 900,
                        width: body_width,
                    }}
                >
                    <p id="I" class="header_paper">
                        I. Abstract
                        <Divider direction="horizontal" />
                    </p>
                    <p class="body_paper">
                        <ImageCaption
                            float="right"
                            padding="0 0 0 20px"
                            width={250}
                            image_url={figure_0}
                        />
                        <PIndent />
                        Built in 2004, the Liberty Bridge is an especially
                        unique ring cable suspension bridge located in
                        <Typography.Text
                            class="background"
                            style={{ color: "transparent" }}
                        >
                            ..
                        </Typography.Text>
                        <a
                            href="https://earth.google.com/web/search/Liberty+Bridge,+South+Main+Street,+Greenville,+SC/@34.84447936,-82.4012147,266.73693848a,0d,79.24553092y,69.29942121h,120.92795385t,0r/data=CigiJgokCULA3ABf10hAETSPMqE5ljBAGQWG7xiC-U7AIZfrch2xUVzAIhoKFkZIVHNiWmJrVEppOHR2YWliNVktOGcQAg"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Greenville, South Carolina
                        </a>
                        . The bridge has a distinct highly curved pedetrian deck
                        and is suspended on only one side. It is composed of
                        four main sections: the reinforced concrete curved
                        walking deck, the triangular underbelly frame for the
                        deck, the horizontal cables located below the triangular
                        frame, and the cable suspension towers which stand to
                        the outside of the curved deck and uphold the entire
                        structure.
                        <PIndent br={true} />
                        In order to understand Liberty Bridge’s unique design,
                        one should analyize the components individually in
                        reverse order, then one can better understand how the
                        individual components function in unison together. After
                        the bridge’s overall composition is numerically
                        analyzed, its safety factor of construction will be
                        approximated, as well as what forces would be required
                        to cause it to become structurally unsound and to fail.
                        This analysis will take place by taking a
                        cross-sectional side-view of one segment of the bridge.
                        This segment will include one triangular truss, its
                        respectively attached suspension cable, and the
                        accompanying pedestrian deck.
                    </p>

                    <p id="II" class="header_paper">
                        II. Conceptual Analysis
                        <Divider direction="horizontal" />
                    </p>
                    <p class="body_paper">
                        <PIndent />
                        Firstly, the suspension towers will be observed. The
                        towers stand not only upwards, but also away from the
                        bridge itself. The towers’ cables are made of steel, are
                        roughly 3 cm in diameter, and connect to the bridge at
                        an average 35-degree angle. From these initial
                        observations, even before quantities are calculated, it
                        can be concluded that a large fraction of the tower’s
                        cable force is horizontal and pulls the bridge not only
                        upwards, but also outwards as well. Nevertheless, the
                        tower focuses most of its force upwards, and it seems to
                        be the only upwards force for the bridge. Therefore,
                        moving onto the next sections, other upholding forces
                        don’t need to be sought out. Rather, horizontal forces
                        acting against the tower’s horizontal force should be
                        focused on.
                        <PIndent br={true} />
                        Next, there are three underbelly horizontal steel cables
                        arcing away from the tower in the shape of a partial
                        ring. These three ring cables are about 8 cm thick and
                        are pulled in tension outwards, presumably by the afore
                        mentioned towers. These cables are much greater than the
                        tower cables, and they dedicate all their force
                        horizontally against the tower’s smaller angled cables.
                        Therefore, it is likely that there is another component
                        that helps the smaller tower cables pull against the
                        larger ring cables.
                        <PIndent br={true} />
                        Moving on, the triangular frame is considered. The frame
                        is mounted to the ring cables only on one side and
                        should be trying to rotate counterclockwise downwards to
                        the ground. However, something must be pulling or
                        pushing them clockwise to keep them upright and level.
                        The tower is first suspect, but the tower’s cables seem
                        too vertically angled to be exerting that much force
                        upon the triangular frame.
                        <ImageCaption
                            float="right"
                            padding="20px 0 0 20px"
                            width={"250px"}
                            image_url={figure_1}
                            header="Figure 1:"
                            br={true}
                            title=" Conceptual Forces Working upon Liberty Bridge"
                        />
                        <PIndent br={true} />
                        Also, the tower already seems overpowered by the
                        horizontal force of the ring cables; it does not seem
                        likely that the tower cables could additionally deal
                        with the torsional force of the frame by itself.
                        Therefore, there must still be something else that
                        assists the towers to balance out the horizontal forces.
                        <PIndent br={true} />
                        Finally, the concrete deck is taken into account. It is
                        also arced like the ring cables, but concrete’s material
                        properties tell a different story than that of the steel
                        ring cables. Cables are used for tension. Its arc
                        endpoints point to the vector-direction of its force
                        upon an object. Concrete, however, is used for
                        compressive forces, and whenever it is in an arc, the
                        arc’s rounded crest points towards where the concrete
                        pushes. The concrete deck’s arcing end faces against the
                        ring cables and against the rotation force of the frame.
                        The deck pushes both the sections towards the suspension
                        towers. Therefore, it is very likely that the concrete
                        deck is the counterforce needed to balance out the other
                        net forces of the structure that act against the towers.
                    </p>

                    <p id="III" class="header_paper">
                        III. Materials and Measurements
                        <Divider direction="horizontal" />
                    </p>
                    <p class="body_paper">
                        <PIndent />
                        The Liberty Bridge’s specific construction materials are
                        not available for analysis, but industry standard
                        materials are nevertheless available to get a general
                        estimate of the bridge’s strengths and design. This
                        analysis will assume four things. Firstly, the concrete
                        walking deck is steel rebar reinforced concrete. This
                        material is beyond popular for most every construction
                        project and immaterial to include. Secondly, the
                        triangular frame is 50W steel. This steel is similar to
                        common A-36 steel, but it is stronger, having a higher
                        yielding stress point of about 35 kN/cm2 (50 ksi). 50W
                        steel’s alloy composition is also more resistant to
                        atmospheric corrosion, making it a popular choice for
                        exposed construction projects. Thirdly, the cables are
                        A-586 steel wire. Like reinforced concrete, A-586 steel
                        wire is a staple for many suspension projects and is a
                        fairly safe assumption to make for analyzing the
                        bridge’s general properties. Finally, in order to
                        simplify the analysis process, negligible items such as
                        the handrails, the guard rail’s steel band mount, and
                        connectors like rivets will be excluded from the numeric
                        review.
                        <ImageCaption
                            float="right"
                            padding="20px 0 0 0"
                            width={"100%"}
                            image_url={table_1}
                            header="Table 1:"
                            title=" Dimensions of Liberty Bridge"
                        />
                        <PIndent br={true} />
                        Rather than using official second-handed measurements of
                        Liberty Bridge, this analysis relies on measurements
                        manually taken by hand. Simple tools like tape measurers
                        and magnetic angle finders were used to measure the core
                        components of the bridge. These measurements do differ
                        from official recorded measurements, but they will
                        nevertheless aptly provide a general review of the
                        bridge’s characteristics. For reference during the
                        numeric analysis section, Table 1 above contains these
                        hand-measured values.
                    </p>

                    <p id="IV" class="header_paper">
                        IV. Numeric Analysis
                        <Divider direction="horizontal" />
                    </p>
                    <p class="body_paper">
                        <ImageCaption
                            float="right"
                            padding="0 0 0 20px"
                            width={"400px"}
                            image_url={figure_2}
                            header="Figure 2:"
                            title=" Preliminary Diagram of Forces"
                        />
                        <PIndent />
                        In order to create a model for Liberty Bridge, a
                        tentative sketch of forces acting upon the bridge with
                        respect to a unifying origin must be established and
                        four numeric objectives must be completed in sequential
                        order. First, each primary object’s weight and center of
                        gravity, especially the hollow triangular frame, must be
                        approximated, Second, the tower cables’ tension must be
                        approximated using the sum of Y-axis forces. Third, the
                        concrete deck’s horizontal force must be approximated
                        using the sum of moments. Fourth, the ring cables’
                        tension must be approximated using the sum of X-axis
                        forces. These steps sequentially depend on each other’s
                        numeric results, so these steps of analysis must be
                        completed in the same order as they were presented.
                        Using the conceptual review and the angle of the
                        uplifting cables from sections II. and III, Figure 2 can
                        be sketched with respect to the arbitrary origin shown.
                        <Space
                            direction="vertical"
                            style={{ float: "right", paddingTop: "25px" }}
                        >
                            <ImageCaption
                                float="right"
                                padding="25px 0 0 20px"
                                width={"320px"}
                                image_url={equation_1}
                                header="Equations 1:"
                                title=" Uniform CoG's"
                            />
                            <ImageCaption
                                float="right"
                                padding="25px 0 0 20px"
                                width={"320px"}
                                image_url={equation_2}
                                header="Equations 2:"
                                title=" Composite CoG"
                            />
                        </Space>
                        <PIndent br={true} />
                        Only three respective centers of gravity (CoG) are
                        required for this analysis: the pedestrian deck, the
                        triangular frame, and the joint block for the ring
                        cables. The steel joint brick and concrete deck are both
                        simple rectangular shapes. If a rectangular material’s
                        density is evenly dispersed, the given object’s CoG is
                        merely half its width and half its height, as shown in
                        Equations 1. Both rectangular items’ CoG’s are recorded
                        in Table 3. Likewise, given a triangular object has a
                        uniform density, the object’s CoG may be found by taking
                        the average location of its three corners, as denoted in
                        Equations 2. However, in the case of the frame, one will
                        need to adjust for the frame’s additional hollow section
                        and side-beam sections when finding the object’s CoG.
                        Equations 2 is used to account for composite object
                        CoG’s, and the frame’s specifications were input into
                        Equations 3 to find the frame’s composite CoG. The
                        calculations for the composite CoG and the composite
                        weight of the triangular frame are shown in Table 2, and
                        the net results are stored in Table 3. Note that in the
                        z-axis blue cross bars have only had their weight
                        recorded. Since they are located directly above the blue
                        joint block, their respective CoG’s are irrelevant, and
                        only their net weight is pertinent to the numeric
                        analysis.
                        <ImageCaption
                            float="right"
                            padding="10px 0 15px 0"
                            width={"100%"}
                            image_url={equation_3}
                            header="Equations 3:"
                            title=" Implementation of Composite CoG"
                        />
                        <ImageCaption
                            float="right"
                            padding="15px 0 0 20px"
                            width={"350px"}
                            image_url={figure_3}
                            header="Figure 3:"
                            br={true}
                            title=" Composite CoG is in Gold.
                            Component CoG’s are colored according to their
                            respective shape."
                        />
                        <PIndent br={true} />
                        Figure 3 diagrams the frame’s composite CoG. Notice how
                        the composite CoG has its x-axis affected by the orange
                        side bars much more than normally would be expected.
                        This is because the blue hollow section removes much of
                        the purple main frame. Note, the following data will be
                        used in the upcoming analysis sections: the frame’s
                        composite CoG and force from Table 2, the summed yellow
                        weight column from Table 3, and the jointly summed
                        weights of the concrete deck and the pedestrian
                        occupancy limit.
                        <div style={{ float: "right" }}>
                            <Row justify="space-around">
                                <ImageCaption
                                    float="right"
                                    padding="10px 0 0 0"
                                    width={"300px"}
                                    image_url={table_2b}
                                    header="Table 2:"
                                    br={true}
                                    title=" CoG and Weight for Y-axis."
                                />
                                <ImageCaption
                                    float="right"
                                    padding="10px 0 0 0"
                                    width={"300px"}
                                    image_url={table_2a}
                                    header="Table 2:"
                                    br={true}
                                    title=" CoG and Weight for X-axis."
                                />
                            </Row>
                            <ImageCaption
                                float="right"
                                padding="10 0 0 0"
                                width={"100%"}
                                image_url={table_3}
                                header="Table 3:"
                                title=" Resulting Weights and CoG’s of Primary Bridge Components"
                            />
                        </div>
                    </p>
                    {/* <Divider direction="horizontal"/> */}
                    <p class="body_paper">
                        <PIndent />
                        <ImageCaption
                            float="right"
                            padding="10px 0 0 20px"
                            width={"350px"}
                            image_url={figure_4}
                            header="Figure 4:"
                            title=" Implementation of Composite CoG"
                        />
                        The cables’ tension force can now be determined by using
                        the sum of Y-axis forces. The net Y-negative force is
                        roughly 72.6 kN. Since the tower cables are the only
                        upwards force, they must produce the entire Y-positive
                        reactionary force of 72.6 kN to balance out the weight.
                        With this value in mind, Equations 4 can be manipulated
                        into providing a pathway to find the resulting net cable
                        tension and horizontal cable tension. The resulting drop
                        cable forces are stored in Table 4. The Y-axis and net
                        tension values will be used for later calculations.
                        <ImageCaption
                            float="right"
                            padding="10px 0 0 0"
                            width={"100%"}
                            image_url={equation_4}
                            header="Equations 4:"
                            br={true}
                            title=" Diagram of
                            X-axis forces in purple, Y-axis forces in yellow,
                            and remaining unknown deck-X and ring-X forces."
                        />
                    </p>
                    <p class="body_paper">
                        <PIndent br={true} />
                        <ImageCaption
                            float="right"
                            padding="10px 0 0 20px"
                            width={"350px"}
                            image_url={equation_5}
                            header="Equations 5:"
                            br={true}
                            title=" Method of Moments for a Static System."
                        />
                        Having resolved the previous force vectors, the concrete
                        deck’s force can now be resolved by using the sum of
                        moments. A sum of moment forces is merely setting a
                        given point in a system as a central point of rotation.
                        Once an origin point is set, two things are now
                        possible. One, any force sourced within the origin point
                        can be ignored; these forces will not cause any torque
                        on itself. Two, all the neighboring forces can now be
                        resolved so that the net torque about the central axis
                        will equal zero. As shown in Equations 5, if a static
                        system is assumed, all the clockwise rotation forces
                        (moments) must equal the counterclockwise forces. For
                        Liberty Bridge, the joint blocks were set as the point
                        of rotation, and all the other non-block force vectors
                        were analyzed to see what torsion forces they applied on
                        the blocks. As shown in Figure 5, the ring cables and
                        joint block forces may be discarded, since they do not
                        torsionally affect themselves. The other forces all
                        cause either a clockwise or a counterclockwise
                        respective force on the joint blocks, and they work
                        against each other to create zero net torsional force on
                        the block. Each force and its respective value from the
                        moment origin was input into Table 4, and their
                        resulting individual moment forces were inserted,
                        including whether each given force was a negative
                        clockwise force or a positive counterclockwise force.
                        Finally, the clockwise force was subtracted from the
                        counterclockwise to resolve the deck’s x-axis force.
                        Since all other X-axis values are accounted for, the
                        deck X-axis force must pick up the resulting difference
                        of the system to maintain static equilibrium. As the
                        conceptual review had predicted, the deck does indeed
                        push outwards toward the suspension towers.
                        <Space
                            direction="vertical"
                            style={{ float: "right", paddingTop: "25px" }}
                        >
                            <ImageCaption
                                float="right"
                                padding="20px 0 0 20px"
                                width={"350px"}
                                image_url={figure_5}
                                header="Figure 5:"
                                title=" Implementation of Composite CoG"
                            />
                            <ImageCaption
                                float="right"
                                padding="20px 0 80px 20px"
                                width={"260px"}
                                image_url={equation_6}
                                header="Equation 6:"
                                title=" Sum of X-components"
                            />
                        </Space>
                        <PIndent br={true} />
                        Once all the other components were resolved, the Liberty
                        bridge’s ring cables’ tension was able to be resolved
                        using the sum of X-axis forces. The bridge only has
                        three active horizontal force, two of which are already
                        known quantities. The ring cables’ horizontal force was
                        readily found by setting the net X-positive ring cable
                        tension equal to the net X-negative tension of the tower
                        cables and of the deck, as shown in Equations 6. The
                        resulting value for the ring cables’ X-axis tension was
                        is stored in Table 4. Looking at the horizontal forces
                        from overhead, one can observe that the ring cables pull
                        the bridge inwards, applying a strangling force to the
                        deck. This strangling force seeks to compress the deck
                        inwards. However, as the conceptual review predicted,
                        the tower cables are tension against the ring cables,
                        and the deck does exert a reactionary compressive force
                        against the ring cables.
                        <ImageCaption
                            float="right"
                            padding="10px 0 0 0"
                            width={"100%"}
                            image_url={table_4}
                            header="Table 4:"
                            title="  Resulting values from Sum of Y, Sum of Moments, and Sum of X."
                        />
                    </p>

                    <p id="V" class="header_paper">
                        V. Safety Factor Analysis
                        <Divider direction="horizontal" />
                    </p>
                    <p class="body_paper">
                        <PIndent />
                        Now that a general model of the bridge’s design and
                        forces has been formed, a tentative factor of safety
                        (FoS) for the structure can be assessed. In construction
                        or design, an FoS compares how much stress a given
                        designed component should handle on a daily basis versus
                        the actual stress that the design could handle before it
                        would be compromised. To find the FoS’s for the cables
                        and deck, To find the FoS’s for the cables and deck, the
                        strengths of the individual components must first be
                        found.
                        <ImageCaption
                            float="right"
                            padding="20px 0 0 20px"
                            width={"540px"}
                            image_url={table_5}
                            header="Table 5:"
                            br={true}
                            title=" Material Properties and Resulting Strength, for the Given Area"
                        />
                        Table 5 lists the relevant yield stress values, the
                        force needed to start deforming a given material, of the
                        bridge’s components. Multiply each component’s yield
                        stress value with its respective surface area from Table
                        1 to find its strength of the material implemented into
                        the bridge and its stress load before failure. To derive
                        the FoS, each component’s failure load and yield stress
                        are gathered together into Table 6. Using Equations 7,
                        each component’s FoS is calculated.
                        <Space direction="vertical" style={{ float: "right" }}>
                            <ImageCaption
                                float="right"
                                padding="20px 0 0 20px"
                                width={"450px"}
                                image_url={table_6}
                                header="Table 6:"
                                title=" Resulting Stresses and FoS"
                            />
                            <ImageCaption
                                float="right"
                                padding="10px 0 0 20px"
                                width={"450px"}
                                image_url={table_7}
                                header="Table 7:"
                                title=" Failing Stresses and FoS"
                            />
                            <ImageCaption
                                float="right"
                                padding="10px 0 0 0"
                                width={"220px"}
                                image_url={equation_7}
                                header="Equations 7:"
                            />
                        </Space>
                        <PIndent br={true} />
                        The resulting factor of safety for the entire structure
                        is approximately 1.3, meaning that Liberty Bridge can
                        technically support an additional 33% more weight before
                        at least one component will fail. The bridge’s ring
                        cables have this value of 1.3. A chain is only as strong
                        as its weakest link, and the same applies to
                        construction FoS. As shown in Table 6, the ring cable
                        has the lowest FoS of 1.3 out of all the components
                        considered. This rating seems consistent with the rating
                        standards denoted by EngineeringToolbox.com. It states
                        that materials with a FoS of 1.3 to 1.5 are, “for use
                        with highly reliable materials where loading and
                        environmental conditions are not severe and where weight
                        is an important consideration.” The other components can
                        support proportionally more weight. The concrete deck
                        has a safety value of over 200. This is to be expected
                        for two reasons. One, concrete is beyond renown for its
                        compressive strength, and the concrete deck additionally
                        has a large surface area to distribute the pressure
                        exerted on it. Two, the deck’s compression is designed
                        to merely support the tower’s drop cables as the deck
                        becomes more heavily loaded. The deck is not meant to
                        bear the brunt of the bridge’s stress. Finally, the
                        tower drop cables have a FoS of 6.4. This value falls
                        short the recommended 8-9 factor for wire ropes, yet it
                        does fall within the 5-7 factor recommended for
                        structural steel in bridges. While the 6.4 rating is not
                        ideal, it does function acceptably for the purpose of a
                        general over of the bridge’s structure.
                        <PIndent br={true} />
                        It would seem that the weakest link for the structure
                        are the horizonal ring cables. Note, however, that the
                        ring cables span the entire length of the bridge,
                        whereas the tower cables only support one cutout
                        subsection per wire. If the ring cables were to fail, an
                        additional 33% weight would need to be applied on every
                        section, not just the singular one previously
                        considered. Also, recall that Liberty Bridge has a drop
                        cable per each truss section, but it possesses a
                        singular overarching cable that links the two towers and
                        their child section cables together. This singular
                        parent cable is highly suspect to be weaker than the
                        horizontal ring cables. Other components like the
                        concrete foundation and joints were also not considered
                        in the generalized analysis. However, the parent cable
                        and other components were not able to be readily
                        measured and is therefore beyond the scope of this
                        general analysis.
                        <PIndent br={true} />
                        Nevertheless, in conclusion, now that FoS have been
                        derived, it is possible to investigate what forces would
                        be required to compromise the structural integrity of
                        the bridge. Currently, the bridge is specified to hold
                        up to 50 pedestrians per triangular truss section,
                        roughly 1,750 pedestrians at once. Again, Table 6
                        displays the FoS’s at this load. If 85 pedestrians per
                        truss section were instead loaded per section, roughly
                        2,975 net pedestrians, the FoS’s changes to those
                        reflected in Table 7. For reference, 85 pedestrians per
                        section is roughly equivalent to placing a military
                        armored hummer with 4 heavily armored soldiers on each
                        truss section. At this stress load, the entire structure
                        is compromised. When a component’s FoS drops below 1,
                        that means that the present stress acting on the system
                        has overcome the maximum strength of the given support
                        and has compromised the structure. In the case of
                        Liberty Bridge, the ring cables will no longer support
                        the x-axis tension, and they will break. If this were to
                        occur, there would be a great surge of force directed
                        towards the towers. The towers would likely collapse to
                        the ground, angling away from the bridge as they
                        descend. The entire causeway would fall crashing down.
                        So then, a mere moment after being overloaded, the
                        entire bridge would collapse with little warning.
                    </p>

                    <p id="REF" class="header_paper">
                        References:
                        <Divider direction="horizontal" />
                    </p>
                    <Space
                        direction="vertical"
                        size="large"
                        class="body_paper"
                        style={{ textAlign: "left" }}
                    >
                        <div>
                            1: <PIndent />
                            <a href="https://bju.instructure.com/courses/13910/files/1133042/download?download_frd=1">
                                Cable Tension.
                            </a>
                        </div>
                        <div>
                            2: <PIndent />
                            <a href="https://bju.instructure.com/courses/13910/files/1133042/download?download_frd=1">
                                Cable Weight.
                            </a>
                        </div>
                        <div>
                            3: <PIndent />
                            <a href="https://bju.instructure.com/courses/13910/files/1133041/download?download_frd=1">
                                Steel Tension.
                            </a>
                        </div>
                        <div>
                            4: <PIndent />
                            <a href="https://www.onlinemetals.com/en/weight-calculator">
                                Steel Weight.
                            </a>
                        </div>
                        <div>
                            5: <PIndent />
                            <a href="https://www.wsdot.wa.gov/eesc/bridge/designmemos/03-2000.htm">
                                Concrete Weight.
                            </a>
                        </div>
                        <div>
                            6: <PIndent />
                            <a href="https://concrete.promatcher.com/articles/Strength-of-Concrete-PSI-2903">
                                Concrete Compression.
                            </a>
                        </div>
                        <div>
                            7: <PIndent />
                            <a href="https://www.wikihow.com/Calculate-the-Center-of-Gravity-of-a-Triangle">
                                Center of Gravity for a Triangle.
                            </a>
                        </div>
                        <div>
                            8: <PIndent />
                            <a href="https://www.engineeringtoolbox.com/factors-safety-fos-d_1624.html">
                                Factor of Safety for Construction.
                            </a>
                        </div>
                    </Space>
                </Space>
            </Row>

            <BackTop style={{ position: "relative" }} />
        </div>
    );
};

export default Page_Bio;
