import React from "react";
import { Player } from 'video-react';
import "../../node_modules/video-react/dist/video-react.css";
import { Space } from "antd"

class VideoPlayer extends React.Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef();
        this.state = {
            video_dim: {
                height: this.props.default_height,
                width: this.props.default_width
            },
        };
    }

    componentDidMount = () => {
        // myRef only has a current property
        // console.log(this.myRef);
        // myRef.current is what we are interested in
        // console.log(this.myRef.current);
        // subscribe state change
        this.myRef.subscribeToStateChange(this.handleStateChange.bind(this));
    }


    handleStateChange(state) {
        if ((!state.isActive && state.paused) || state.ended) {
            this.setState({
                video_dim: {
                    height: this.props.default_height,
                    width: this.props.default_width,
                }
            })
            // console.log("DEFAULT_STATE", state, this.myRef);
        }
        else {
            this.setState({
                video_dim: {
                    height: this.props.active_height,
                    width: this.props.active_width,
                }
            })
            // console.log("ACTIVE_STATE: ", state, this.myRef);
        }
    }
    render() {
        return (
            <div
                style={{
                    float: `${this.props.float}`,
                    padding: `${this.props.padding}`
                }}>
                <Space wrap="true"
                    direction="horizontal"
                    class="body_image_comment"
                    style={{ width: `${this.state.video_dim.width}` }}
                >

                    <div>
                        {this.props.header ? (<>{this.props.header}</>) : null}
                        {this.props.br ? (<br />) : null}
                        {this.props.title ? (<>{this.props.title}</>) : null}
                    </div>
                </Space>
                <Player
                    ref={player => {
                        this.myRef = player;
                    }}
                    src={this.props.video}
                    playsInline
                    poster={this.props.poster}
                    fluid={false}
                    width={this.state.video_dim.width}
                    height={this.state.video_dim.height}
                />
            </div>
        )
    }
};
export default VideoPlayer;