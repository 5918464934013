import React, { useEffect } from "react";

import useWindowDimensions from "../redux/misc/windowDimensions";
import PageProjectScience from "./Page_Project_Science";
import PageStudychan from "./Page_Studychan";
import PageStat from "./Page_Stat";
import PageMext from "./Page_Mext";
import PageExo from "./Page_Exo";
import PageBio from "./Page_Bio";
import PageHeader from "./Page_Header";
import icon_bio from "../media/_bio/bio_icon.png";
import icon_bridge from "../media/_stat/bridge_icon.png";
import icon_project_science from "../media/_project_sceince/icon_ps.png";
import icon_exo from "../media/_exo/cyberdyne_icon.png";
import icon_studychan from "../media/_studychan/cover_icon.png";
import icon_mext from "../media/_mext/icon_mext.png";
import icon_sun from "../media/sun.png";
import icon_moon from "../media/moon.png";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../redux/utility/actions/actions";

import { Layout, Typography, Space, Row, Switch, Tabs, Avatar } from "antd";
const { Header, Footer, Content } = Layout;
const { TabPane } = Tabs;
const { Text } = Typography;

function CustomLayout(props) {
    let dispatch = useDispatch();

    var currentPage = useSelector((state) => state.currentPage);

    const changeCurrentPage = (value) => {
        dispatch(actions.setPage(value));
        console.log("PAGE CHANGE: ", value);
    };

    var themeMode = useSelector((state) => state.themeMode);

    const changeTheme = (value) => {
        dispatch(actions.setTheme(value ? "dark" : "light"));
    };

    var MextPageVisible =
        (
            new URLSearchParams(window.location.search).get("page") || ""
        ).toLowerCase() === "mext";

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const page = urlParams.get("page");
        if (page) {
            changeCurrentPage(page);
        }
        // eslint-disable-next-line
    }, []);

    //  ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ  //
    //  Responsive Design   //
    //  ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ  //

    const {
        // scr_height, scr_width, scr_SMALL,
        scr_MEDIUM,
        scr_LARGE,
    } = useWindowDimensions();

    const tab_width_small = 110;
    const tab_width_medium = 235;
    const tab_width_large = 350;

    let res_bio_txt = null;
    let res_exo_txt = null;
    let res_mext_txt = null;
    let res_stat_txt = null;
    let res_project_science_txt = null;
    let res_studychan_txt = null;
    let res_skill_header = "Projects:";
    let res_skill_spacer = "______";
    let themeSwitchLeft = 5;
    let themeSwitchTop = 60;
    let headerPadding = "5px 10px 5px 80px";

    if (scr_LARGE) {
        res_bio_txt = "Personal Resume";
        res_mext_txt = "本日のお礼";
        res_project_science_txt = "SaaS MVP - Project Science";
        res_stat_txt = "Architecture - Liberty Bridge";
        res_studychan_txt = "Full-stack Hobby - Studychan Project";
        res_exo_txt = "Mechatronics - Exoskeleton Project";
        res_skill_header = "Projects:";
        res_skill_spacer = "____________________________________";
        themeSwitchLeft = 50;
        themeSwitchTop = 60;
        headerPadding = "5px 40px 5px 80px";
    }
    if (scr_MEDIUM) {
        res_bio_txt = "Resume";
        res_mext_txt = "本日のお礼";
        res_project_science_txt = "SaaS MVP";
        res_stat_txt = "Architecture";
        res_studychan_txt = "Full-stack Hobby";
        res_exo_txt = "Mechatronics";
        res_skill_spacer = "________________________";
        themeSwitchLeft = 20;
        themeSwitchTop = 60;
        headerPadding = "5px 40px 5px 80px";
    }
    // http://localhost:3000/?page=n

    function scrollTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <>
            <Layout>
                <Header
                    id="header"
                    style={{
                        padding: headerPadding,
                        zIndex: 11,
                    }}
                    class={`${themeMode} ${currentPage}`}
                >
                    <Row align="middle" justify="end">
                        {/* <div></div> */}

                        <Switch
                            checked={themeMode === "dark"}
                            onChange={changeTheme}
                            checkedChildren={
                                <img
                                    alt=""
                                    src={icon_moon}
                                    style={{ height: "25px", width: "25px" }}
                                />
                            }
                            unCheckedChildren={
                                <img
                                    alt=""
                                    src={icon_sun}
                                    style={{ height: "25px", width: "25px" }}
                                />
                            }
                            style={{
                                position: "fixed",
                                left: themeSwitchLeft,
                                top: themeSwitchTop,
                            }}
                        />
                        <PageHeader />
                    </Row>
                </Header>

                <Content class={`${themeMode} ${currentPage} custom_scrollbar`}>
                    <div
                        style={{
                            position: "fixed",
                            top: "0px",
                            zIndex: 10,
                            paddingTop: "125px",
                            backgroundColor: "inherit",
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                top: "220px",
                                left: "20px",
                                zIndex: 10,
                                backgroundColor: "transparent",
                            }}
                        >
                            <Text className="subheader_paper notranslate">
                                {res_skill_spacer}
                            </Text>
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                top: "620px",
                                left: "20px",
                                zIndex: 10,
                                backgroundColor: "transparent",
                            }}
                        >
                            <Text className="subheader_paper notranslate">
                                {res_skill_spacer}
                            </Text>
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                top: "215px",
                                left: "20px",
                                zIndex: 10,
                                backgroundColor: "transparent",
                            }}
                        >
                            <Text className="body_paper">
                                {res_skill_header}
                            </Text>
                        </div>

                        <Tabs
                            onTabClick={() => scrollTop()}
                            tabPosition={"left"}
                            tabBarGutter={0}
                            size="large"
                            activeKey={currentPage}
                            // defaultActiveKey="_exo"
                        >
                            <TabPane
                                tab={
                                    <Space
                                        align="baseline"
                                        onClick={() => changeCurrentPage("Bio")}
                                        style={{ padding: "25px 0px 40px 0px" }}
                                    >
                                        {/* <div style={{ height: "100px", width: "1px" }}></div> */}
                                        <Avatar src={icon_bio} />
                                        <div class={"TS"}>{res_bio_txt}</div>
                                    </Space>
                                }
                                key="Bio"
                                style={{ scr_width: "500px" }}
                            ></TabPane>

                            <TabPane
                                tab={
                                    <Space
                                        onClick={() =>
                                            changeCurrentPage("Project_Science")
                                        }
                                        style={{ padding: "25px 0px 25px 0px" }}
                                    >
                                        <Avatar src={icon_project_science} />
                                        <div class={"TS"}>
                                            {res_project_science_txt}
                                        </div>
                                    </Space>
                                }
                                key="Project_Science"
                            ></TabPane>

                            <TabPane
                                tab={
                                    <Space
                                        onClick={() =>
                                            changeCurrentPage("Studychan")
                                        }
                                        style={{ padding: "25px 0px 25px 0px" }}
                                    >
                                        <Avatar src={icon_studychan} />
                                        <div class={"TS"}>
                                            {res_studychan_txt}
                                        </div>
                                    </Space>
                                }
                                key="Studychan"
                            ></TabPane>

                            <TabPane
                                tab={
                                    <Space
                                        onClick={() =>
                                            changeCurrentPage("Stat")
                                        }
                                        style={{ padding: "25px 0px 25px 0px" }}
                                    >
                                        <Avatar src={icon_bridge} />
                                        <div class={"TS"}>{res_stat_txt}</div>
                                    </Space>
                                }
                                key="Stat"
                            ></TabPane>

                            <TabPane
                                tab={
                                    <Space
                                        onClick={() => changeCurrentPage("Exo")}
                                        style={{ padding: "25px 0px 35px 0px" }}
                                    >
                                        <Avatar src={icon_exo} />
                                        <div class={"TS"}>{res_exo_txt}</div>
                                    </Space>
                                }
                                key="Exo"
                            ></TabPane>

                            {MextPageVisible ? (
                                <TabPane
                                    tab={
                                        <Space
                                            onClick={() =>
                                                changeCurrentPage("Mext")
                                            }
                                            style={{
                                                padding: "35px 0px 25px 0px",
                                            }}
                                        >
                                            <Avatar src={icon_mext} />
                                            <div class={"notranslate TS"}>
                                                {res_mext_txt}
                                            </div>
                                        </Space>
                                    }
                                    key="Mext"
                                ></TabPane>
                            ) : null}
                        </Tabs>
                    </div>

                    <Tabs
                        tabPosition={"left"}
                        tabBarGutter={50}
                        size="large"
                        renderTabBar={null}
                        activeKey={currentPage}
                    >
                        <TabPane
                            tab={
                                <Space align="baseline">
                                    <Avatar src={icon_bio} />
                                    <div class={"TS"}>{res_bio_txt}</div>
                                </Space>
                            }
                            key="Bio"
                            style={{ scr_width: "500px" }}
                        >
                            <PageBio
                                tab_width_small={tab_width_small}
                                tab_width_medium={tab_width_medium}
                                tab_width_large={tab_width_large}
                            />
                        </TabPane>

                        <TabPane
                            tab={
                                <Space>
                                    <Avatar src={icon_studychan} />
                                    <div class={"TS"}>{res_studychan_txt}</div>
                                </Space>
                            }
                            key="Project_Science"
                        >
                            <PageProjectScience
                                tab_width_small={tab_width_small}
                                tab_width_medium={tab_width_medium}
                                tab_width_large={tab_width_large}
                            />
                        </TabPane>

                        <TabPane
                            tab={
                                <Space>
                                    <Avatar src={icon_studychan} />
                                    <div class={"TS"}>{res_studychan_txt}</div>
                                </Space>
                            }
                            key="Studychan"
                        >
                            <PageStudychan
                                tab_width_small={tab_width_small}
                                tab_width_medium={tab_width_medium}
                                tab_width_large={tab_width_large}
                            />
                        </TabPane>

                        <TabPane
                            tab={
                                <Space>
                                    <Avatar src={icon_bridge} />
                                    <div class={"TS"}>{res_stat_txt}</div>
                                </Space>
                            }
                            key="Stat"
                        >
                            <PageStat
                                tab_width_small={tab_width_small}
                                tab_width_medium={tab_width_medium}
                                tab_width_large={tab_width_large}
                            />
                        </TabPane>

                        <TabPane
                            tab={
                                <Space>
                                    <Avatar src={icon_exo} />
                                    <div class={"TS"}>{res_exo_txt}</div>
                                </Space>
                            }
                            key="Exo"
                        >
                            <PageExo
                                tab_width_small={tab_width_small}
                                tab_width_medium={tab_width_medium}
                                tab_width_large={tab_width_large}
                            />
                        </TabPane>

                        {MextPageVisible ? (
                            <TabPane
                                tab={
                                    <Space>
                                        <Avatar src={icon_mext} />
                                        <div class={"TS"}>{res_mext_txt}</div>
                                    </Space>
                                }
                                key="Mext"
                            >
                                <PageMext
                                    tab_width_small={tab_width_small}
                                    tab_width_medium={tab_width_medium}
                                    tab_width_large={tab_width_large}
                                />
                            </TabPane>
                        ) : null}
                    </Tabs>
                </Content>

                <Footer class={themeMode}></Footer>
            </Layout>
        </>
    );
}

export default CustomLayout;
