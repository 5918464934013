import React from "react";
import { Image, Space } from "antd";

const Image_Caption = (props) => {
    return (
        <div
            class="body_image"
            style={{
                float: `${props.float}`,
                padding: `${props.padding}`,
            }}
        >
            <Space
                wrap="true"
                direction="horizontal"
                class="contrast_faded_paper"
                style={{ width: `${props.width}` }}
            >
                <div>
                    {props.header ? <>{props.header}</> : null}
                    {props.br ? <br /> : null}
                    {props.title ? <>{props.title}</> : null}
                </div>
            </Space>
            <div class="pointer">
                <Image
                    width={props.width}
                    height={props.height}
                    src={props.image_url}
                />
            </div>
        </div>
    );
};
export default Image_Caption;
