import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    themeMode: "dark",
    currentPage: "Bio",
};

const setTheme = (state, action) => {
    let data = {
        themeMode: action.data,
    };
    return updateObject(state, data);
};

const setPage = (state, action) => {
    let data = {
        currentPage: action.data,
    };
    return updateObject(state, data);
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_THEME:
            return setTheme(state, action);

        case actionTypes.SET_PAGE:
            return setPage(state, action);

        default:
            return state;
    }
};

export default reducer;
