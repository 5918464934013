import React from "react";

import bio_icon from "../media/_bio/bio_icon.png";
import background_small from "../media/_mext/mext_background.jpg";
import background_medium from "../media/_mext/mext_background.jpg";
import background_large from "../media/_mext/mext_background.jpg";
import icon_translate from "../media/translate.png";
import icon_exo_link from "../media/_mext/Exo_Preview.png";
import icon_mextTimeTable from "../media/_mext/Mext_TimeTable.png";
import icon_mextpaperPreview from "../media/_mext/Mext_Paper_Preview.png";
import pdf_mextpaper_link from "../media/_mext/2021_FieldStudy_Research.docx";
import useWindowDimensions from "../redux/misc/windowDimensions";
import "../../node_modules/video-react/dist/video-react.css";
import ImageCaption from "../components/Image_Caption";

import { CopyOutlined, CopyFilled } from "@ant-design/icons";
import { Avatar, Space, Typography, Col, Divider, Row, BackTop } from "antd";
const { Text } = Typography;

const Page_Bio = (props) => {
    const {
        scr_height,
        scr_width,
        // scr_SMALL,
        scr_MEDIUM,
        scr_LARGE,
    } = useWindowDimensions();

    // const active_video_dim = {
    //     height: 400,
    //     width: 300,
    // };
    // const default_video_dim = {
    //     height: 100,
    //     width: 100,
    // };
    // const [video_dim, setVideo_dim] = useState(default_video_dim);
    // const [video_active, setVideo_active] = useState(false);

    // function toggle_video_active() {
    //     if (!video_active) {
    //         setVideo_active(true);
    //         setVideo_dim(active_video_dim);
    //     } else {
    //         setVideo_dim(default_video_dim);
    //         setVideo_active(false);
    //     }
    // }

    var bio_pic = background_small;
    var body_width = scr_width - props.tab_width_small;
    var body_tab_offset = 0;
    // var bio_quote_width = 200;
    var justify_body_text = "center";
    var parallax_avater_offset = "15vw";
    var icon_size = scr_width / 10;
    var attachmentHeight = 20;
    var attachmentRatio = 1.2;

    if (scr_MEDIUM) {
        bio_pic = background_medium;
        body_width = scr_width - props.tab_width_medium;
        body_tab_offset = props.tab_width_medium;
        // bio_quote_width = 550;
        parallax_avater_offset = "";
        icon_size = scr_width / 30;
    } else if (scr_LARGE) {
        bio_pic = background_large;
        body_width = scr_width - props.tab_width_large;
        body_tab_offset = props.tab_width_large;
        // bio_quote_width = 700;
        // justify_body_text = "center";
        parallax_avater_offset = "";
        icon_size = scr_width / 30;
    }

    return (
        <div
            style={{
                width: body_width,
            }}
            class={"Page_Mext"}
        >
            <div
                style={{
                    width: "100%",
                    height: scr_height * 0.83,
                    overflow: "visible",
                }}
            >
                <div
                    style={{
                        backgroundColor: "black",
                    }}
                >
                    <div
                        class="parallax animated fadeIn"
                        style={{
                            backgroundImage: `url(${bio_pic})`,
                            height: scr_height * 0.83,
                            width: body_width,
                            backgroundAttachment: "fixed",
                            backgroundPosition: "right top", // center
                            backgroundRepeat: "no-repeat",
                            backgroundSize: `${
                                body_width * 1.1
                            }px ${scr_height}px`,
                        }}
                    />
                </div>
                <Space
                    wrap={true}
                    align="start"
                    direction="vertical"
                    style={{
                        position: "absolute",
                        left: body_width * 0.2 + body_tab_offset,
                        top: scr_height * 0.28,
                        backgroundColor: "transparent",
                    }}
                >
                    <Row
                        justify="start"
                        style={{
                            backgroundColor: "transparent",
                        }}
                    >
                        <Avatar
                            src={bio_icon}
                            size={icon_size * 4}
                            style={{
                                backgroundColor: "transparent",
                                left: parallax_avater_offset,
                                maxWidth: icon_size * 3.5,
                            }}
                        />
                    </Row>
                    <p
                        class="parallax_text notranslate"
                        style={{
                            position: "relative",
                            opacity: 1,
                            textAlign: "left",
                            padding: "15px 15px 15px 30px",
                            borderRadius: "10px 50px 10px 50px",
                            maxWidth: "50vw",
                        }}
                    >
                        お忙しい中、当ホームページをご覧いただき、ありがとうございます。
                        <br />
                        もしご質問等がございましたら、是非ご連絡ください。
                    </p>
                </Space>
            </div>

            <Divider direction="horizontal" />

            <Row justify={justify_body_text}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        padding: "0px 20px 20px 0px",
                        maxWidth: 900,
                        width: body_width,
                    }}
                >
                    <p id="I" class="header_paper notranslate">
                        自己紹介
                    </p>
                    <p class="body_paper notranslate">
                        <b>名前：</b> ジョン・レイトン ( JON LAYTON )
                        <br />
                        <br />
                        <b>生年月日：</b>１９９９年　５月　２１日
                        <br />
                        <br />
                        <b>大学専攻：</b>コンピュータ工学
                        <br />
                        <br />
                        <b>専門分野：</b>ソフトウェア開発・メカトロニクス
                        <br />
                        <br />
                        <b>趣味：</b>言語学、形而上学、建築
                        <br />
                        <br />
                        <div
                            class="fadedBackground"
                            style={{
                                position: "relative",
                                left: "5%",
                                width: "60%",
                                textAlign: "center",
                                border: "solid black 2px",
                                borderRadius: "15px",
                                padding: "5px",
                            }}
                        >
                            <b>免責事項：</b>
                            今のところ、日本語のスキルレベルは低いです。
                            <br />
                            日本語で読みたい方は、画面の隅にある
                            <img
                                alt=""
                                src={icon_translate}
                                style={{ height: "1.5em", width: "1.5em" }}
                            />
                            翻訳者を使ってください。
                        </div>
                    </p>

                    <p class="body_paper">
                        <b class="notranslate">
                            コンピュータサイエンスとメカトロニクスを学びたい理由：
                        </b>
                        I am very interested in helping paraplegics experience
                        the things that most people often take for granted by
                        helping to develop exoskeleton technology and other
                        similar social changing technologies.
                        <br />
                        <br />
                        <b class="notranslate">過去：</b>To accomplish this
                        goal, I designed a prototype mechanical yaw rotation
                        joint during my undergraduate degree.
                        <br />
                        <br />
                        <b class="notranslate">存在：</b>During my master's
                        degree, I hope to use the undergraduate design as a
                        tangible case study to grow personal skills in software
                        development, to learn cybernics, and to offer a new
                        potential model for exoskeleton mobility.
                        <br />
                        <br />
                        <b class="notranslate">将来：</b>After graduating with a
                        master's degree, I hope to continue developing cybernics
                        technology and to continue working with software,
                        neuroscience, and IoT embedded systems.
                    </p>

                    <p class="body_paper notranslate">
                        もし、ご意見ご感想がありましたら、是非ご連絡ください。
                        <Typography.Paragraph
                            className={"TS"}
                            copyable={{
                                icon: [
                                    <CopyOutlined
                                        className="TSPPP"
                                        key="copy-icon"
                                    />,
                                    <CopyFilled
                                        className="TSPPPPP"
                                        key="copied-icon"
                                    />,
                                ],
                                tooltips: ["copy", "email copied!"],
                            }}
                            style={{
                                position: "relative",
                                top: "10px",
                                textAlign: "start",
                            }}
                        >
                            <Text class="subheader_paper TSPP">
                                jonathanlayton99@gmail.com
                            </Text>
                        </Typography.Paragraph>
                    </p>
                </Space>
            </Row>

            <Space
                direction="vertical"
                size="small"
                style={{ width: body_width }}
            >
                <Row justify="space-around">
                    <Text class="subheader_paper">添付 ファイル：</Text>
                </Row>

                <Row justify="space-around">
                    <Col span={8} style={{ padding: body_width / 30 }}>
                        <Row justify="center">
                            <Space
                                wrap="true"
                                direction="vertical"
                                class="faded_paper"
                                style={{ textAlign: "center" }}
                            >
                                <div
                                    class="fadedBackground"
                                    style={{
                                        height: `${attachmentHeight}vw`,
                                        width: `${
                                            attachmentHeight * attachmentRatio
                                        }vw`,
                                        textAlign: "center",
                                        borderRadius: "20px",
                                    }}
                                >
                                    <a
                                        href="https://jonathanlayton99.pythonanywhere.com/?page=Page_Exo"
                                        rel="noopener noreferrer"
                                    >
                                        学部プロジェクトの詳細：
                                        <br /> ウェブページのリンク
                                    </a>
                                    <ImageCaption
                                        float="right"
                                        padding="0px 0 0 0px"
                                        width={"100%"}
                                        image_url={icon_exo_link}
                                    />
                                </div>
                            </Space>
                        </Row>
                    </Col>

                    <Col span={8} style={{ padding: body_width / 30 }}>
                        <Row justify="center">
                            <div
                                class="fadedBackground"
                                style={{
                                    height: `${attachmentHeight}vw`,
                                    width: `${
                                        attachmentHeight * attachmentRatio
                                    }vw`,
                                    textAlign: "center",
                                    borderRadius: "20px",
                                }}
                            >
                                <ImageCaption
                                    float="right"
                                    padding="0px 0 0 0px"
                                    width={"100%"}
                                    image_url={icon_mextTimeTable}
                                    header="タイムテーブル："
                                    br={true}
                                    title="クリックして表示"
                                />
                            </div>
                        </Row>
                    </Col>

                    <Col span={8} style={{ padding: body_width / 30 }}>
                        <Row justify="center">
                            <Space
                                wrap="true"
                                direction="vertical"
                                class="faded_paper"
                                style={{ textAlign: "center" }}
                            >
                                <div
                                    class="fadedBackground"
                                    style={{
                                        height: `${attachmentHeight}vw`,
                                        width: `${
                                            attachmentHeight * attachmentRatio
                                        }vw`,
                                        textAlign: "center",
                                        borderRadius: "20px",
                                    }}
                                >
                                    <a
                                        href={pdf_mextpaper_link}
                                        rel="noopener noreferrer"
                                    >
                                        詳細な研究企画 (英語)：
                                        <br />
                                        [ＰＤＦファイル　サイズ：xxxＫＢ]
                                    </a>
                                    <ImageCaption
                                        float="right"
                                        padding="0px 0 0 0px"
                                        width={"100%"}
                                        image_url={icon_mextpaperPreview}
                                    />
                                </div>
                            </Space>
                        </Row>
                    </Col>
                </Row>
            </Space>

            {/* <Row justify={justify_body_text}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        padding: "20px 20px 20px 0px",
                        maxWidth: 900,
                        width: body_width
                    }}>

                    <p class="body_paper notranslate">
                        もし、ご意見ご感想がありましたら、是非ご連絡ください。

                        <Typography.Paragraph
                            className={"TS"}
                            copyable={{
                                icon: [<CopyOutlined className="TSPPP" key="copy-icon" />, <CopyFilled className="TSPPPPP" key="copied-icon" />],
                                tooltips: ['copy', 'email copied!'],
                            }}
                            style={{ position: "relative", top: "10px", textAlign: "start" }}
                        >
                            <Text class="subheader_paper TSPP">
                                jonathanlayton99@gmail.com
                            </Text>
                        </Typography.Paragraph>
                    </p>
                </Space>
            </Row> */}

            <BackTop style={{ position: "relative" }} />
        </div>
    );
};

export default Page_Bio;
