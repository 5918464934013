import React from "react";

import useWindowDimensions from "../redux/misc/windowDimensions";

import {
    LinkedinOutlined,
    GoogleSquareFilled,
    SmileOutlined,
} from "@ant-design/icons";
import { Space, Typography } from "antd";

const Page_Header = (props) => {
    const {
        // scr_height,
        // scr_width,
        // scr_SMALL,
        scr_MEDIUM,
        scr_LARGE,
    } = useWindowDimensions();

    let res_icon_size = 16;
    let spacing = "small";

    if (scr_LARGE) {
        res_icon_size = 20;
        spacing = "large";
    } else if (scr_MEDIUM) {
        res_icon_size = 17;
        spacing = "large";
    }

    return (
        <Space
            align="center"
            size={spacing}
            direction="horizontal"
            wrap={true}
            style={{
                fontSize: `${res_icon_size}px`,
                position: "relative",
                right: "-10px",
            }}
        >
            <Typography.Paragraph
                className={"TS"}
                copyable={{
                    icon: [
                        <GoogleSquareFilled
                            className="TSPPPPP"
                            key="copy-icon"
                        />,
                        <SmileOutlined className="TSPPPPP" key="copied-icon" />,
                    ],
                    tooltips: ["copy", "email copied !!"],
                }}
                style={{ position: "relative", top: "10px" }}
            >
                jonathanlayton99@gmail.com
            </Typography.Paragraph>

            <a
                class="header_body"
                rel="noopener noreferrer"
                href="/Layton_CV.pdf"
                target="_blank"
                download
                style={{
                    borderRadius: "5px",
                    border: "3px solid",
                    padding: "2px",
                }}
            >
                <b>CV</b>
            </a>

            <a
                class="header_body"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/jonathanlayton99/"
                target="_blank"
            >
                <LinkedinOutlined
                    style={{ fontSize: `${res_icon_size * 2}px` }}
                />
            </a>
        </Space>
    );
};

export default Page_Header;
