import React from "react";

import background_small from "../media/_studychan/cover_icon.png";
import background_medium from "../media/_studychan/cover_name_phrase.png";
import background_large from "../media/_studychan/cover_landscape.png";
import useWindowDimensions from "../redux/misc/windowDimensions";
import "../../node_modules/video-react/dist/video-react.css";
import PIndent from "../components/P_Indent";

import { DesktopOutlined, MobileOutlined } from "@ant-design/icons";
import { Space, Typography, Col, Divider, Row, BackTop, Progress } from "antd";
const { Title } = Typography;

const Page_Bio = (props) => {
    const {
        scr_height,
        scr_width,
        // scr_SMALL,
        scr_MEDIUM,
        scr_LARGE,
    } = useWindowDimensions();

    var bio_pic = background_small;
    var body_width = scr_width - props.tab_width_small;
    var justify_body_text = "start";

    if (scr_MEDIUM) {
        bio_pic = background_medium;
        body_width = scr_width - props.tab_width_medium;
        justify_body_text = "center";
    } else if (scr_LARGE) {
        bio_pic = background_large;
        body_width = scr_width - props.tab_width_large;
        justify_body_text = "center";
    }

    return (
        <div
            style={{
                width: body_width,
            }}
            class={"Page_Studychan"}
        >
            <div
                style={{
                    width: "100%",
                    height: scr_height * 0.88,
                    overflow: "visible",
                }}
            >
                <div
                    style={{
                        backgroundColor: "black",
                    }}
                >
                    <div
                        class="parallax animated fadeIn"
                        style={{
                            backgroundImage: `url(${bio_pic})`,
                            height: scr_height * 0.88,
                            width: body_width,
                            backgroundAttachment: "fixed",
                            backgroundPosition: "right top",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: `${
                                body_width * 1.05
                            }px ${scr_height}px`,
                        }}
                    />
                </div>

                <Space
                    wrap={true}
                    align="start"
                    direction="vertical"
                    style={{
                        position: "absolute",
                        right: body_width * 0.2,
                        top: scr_height * 0.68,
                    }}
                >
                    <p
                        class="parallax_text"
                        style={{
                            position: "relative",
                            opacity: 1,
                            textAlign: "left",
                            padding: "15px 15px 15px 30px",
                            borderRadius: "10px 50px 10px 50px",
                        }}
                    >
                        Spunky curiousity
                        <br />
                        and consistent self-study
                        <br />
                        is the best education.
                    </p>
                </Space>
            </div>

            <Divider direction="horizontal" />

            <Row justify={"center"}>
                <Title class="body_paper FWM TSPPPP">
                    <i>ㅡ Work in Progress. Expected Release: 2023 ㅡ</i>
                </Title>
                <Divider direction="horizontal" />
            </Row>

            <Space
                direction="vertical"
                size="small"
                style={{ width: body_width }}
            >
                <Row
                    justify="space-around"
                    className="body_paper"
                    style={{ textAlign: "center" }}
                >
                    {/* <Col span={8} style={{ padding: body_width / 30 }}>
                        <Space size="small" direction="vertical">
                            <Anchor
                                style={{ borderRadius: "40px", padding: "5px" }}
                            >
                                <p
                                    // href="https://studychan.pythonanywhere.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="TSPP FWB"
                                >
                                    <WifiOutlined className="TSPPP" />{" "}
                                    StudyChan.com
                                    <br />
                                    <Text className="FWM TDN">
                                        Check it out!
                                    </Text>
                                </p>
                            </Anchor>
                            <p style={{ paddingTop: "25px" }}>
                                {" "}
                                <p class="TSPP">Community Hub:</p> Browse,
                                Share, Download Content{" "}
                            </p>
                            <Divider direction="horizontal" />
                            <p> Django - React.js - MySQL </p>
                            <Progress
                                type="dashboard"
                                percent={50}
                                width={80}
                                showInfo={true}
                                strokeColor={"green"}
                                trailColor={"#dedeeda9"}
                            />
                            Completion
                        </Space>
                    </Col> */}

                    <Col span={8} style={{ padding: body_width / 30 }}>
                        <Space size="small" direction="vertical">
                            <p
                                class="TSPP"
                                style={{ padding: "5px", fontWeight: "bold" }}
                            >
                                {" "}
                                <DesktopOutlined className="TSPPP" />{" "}
                                StudyChan.com{" "}
                            </p>
                            <p style={{ paddingTop: "25px" }}>
                                {" "}
                                <p class="TSPP">Community Hub:</p> Browse,
                                Share, Download Content{" "}
                            </p>
                            <Divider direction="horizontal" />
                            <p> Django - React.js - MySQL </p>
                            <Progress
                                type="dashboard"
                                percent={40}
                                width={80}
                                showInfo={true}
                                strokeColor={"green"}
                                trailColor={"#dedeeda9"}
                            />
                            Completion
                        </Space>
                    </Col>

                    <Col span={8} style={{ padding: body_width / 30 }}>
                        <Space size="small" direction="vertical">
                            <p
                                class="TSPP"
                                style={{ padding: "5px", fontWeight: "bold" }}
                            >
                                {" "}
                                <DesktopOutlined className="TSPPP" />{" "}
                                StudyChan.exe{" "}
                            </p>
                            <br />
                            <p style={{ paddingTop: "5px" }}>
                                {" "}
                                <p class="TSPP">Admin Hub: </p> Create, Review,
                                Manage Content{" "}
                            </p>
                            <Divider direction="horizontal" />
                            <p> React Native for Desktop - SQLite </p>
                            <Progress
                                type="dashboard"
                                percent={10}
                                width={80}
                                showInfo={true}
                                strokeColor={"green"}
                                trailColor={"#dedeeda9"}
                            />
                            Completion
                        </Space>
                    </Col>

                    <Col span={8} style={{ padding: body_width / 30 }}>
                        <Space size="small" direction="vertical">
                            <p
                                class="TSPP"
                                style={{ padding: "5px", fontWeight: "bold" }}
                            >
                                {" "}
                                <MobileOutlined className="TSPPP" />{" "}
                                StudyChan.apk{" "}
                            </p>
                            <br />
                            <p style={{ paddingTop: "5px" }}>
                                {" "}
                                <p class="TSPP">Study Hub: </p> Daily Review
                                Content{" "}
                            </p>
                            <Divider direction="horizontal" />
                            <p> React Native - SQLite </p>
                            <Progress
                                type="dashboard"
                                percent={0}
                                width={80}
                                showInfo={true}
                                strokeColor={"green"}
                                trailColor={"#dedeeda9"}
                            />
                            Completion
                        </Space>
                    </Col>
                </Row>
            </Space>

            <br />
            <br />

            <Row justify={justify_body_text}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        padding: "20px 20px 20px 0px",
                        maxWidth: 900,
                        width: body_width,
                    }}
                >
                    <p id="I_" class="header_paper FWM TSPPPP">
                        StudyChan wants to provide two services:
                        <Divider direction="horizontal" />
                    </p>

                    <p id="IIb" class="header_paper FWM TSPPP">
                        I. A reference database to quality educators and content
                        for any topic.
                    </p>
                    <p class="body_paper">
                        <PIndent />
                        These days, it's easy to google raw information on
                        specific topics.
                        <PIndent br={true} />
                        But it can be tough to find and organize quality content
                        for advanced in-depth topics.
                        <br />
                        <br />
                        <b class="subheader_paper FWM">
                            StudyChan's ccPick (Content Creators Pick)
                        </b>{" "}
                        database hopes to easily answer questions like:
                        <br />
                    </p>

                    <ul class="body_paper">
                        <li>
                            "Which source has the best explanation of Japanese
                            grammar?"
                            <br />
                            <br />
                        </li>
                        <li>
                            "This guy has nice Calculus 3 videos for solving,
                            but who covers the proofs?"
                            <br />
                            <br />
                        </li>
                        <li>
                            "Where should I go to find good foreign language
                            subtitles?"
                        </li>
                    </ul>

                    <Divider direction="horizontal" />

                    <p id="IIb" class="header_paper FWM TSPPP">
                        II. A fresh and simple way to author, study, and share
                        learning content.
                    </p>

                    <p class="body_paper">
                        <PIndent />
                        There are already many flashcard software available
                        today, but they seem either too lightweight or too
                        complex and dated.
                        <PIndent br={true} />
                        Quizlet is great for cramming terms at school, and Anki
                        Flashcards is ideal for hardcore career learners. But
                        what if you want to review and go deeper than Quizlet
                        without feeling crushed by Anki's complex and demanding
                        statistics?
                    </p>

                    <p class="body_paper">
                        <PIndent />
                        StudyChan will adopt Anki's powerful core ideas of SRS
                        (spaced repetition learning system) and TTS
                        (text-to-speech), but it will also strip away the focus
                        on study statistics and automate many of Anki's
                        technical hassles.
                    </p>

                    <p class="body_paper">
                        StudyChan also wants to{" "}
                        <b class="subheader_paper FWM">add new features</b>{" "}
                        like:
                        <br />
                        <br /> - <b class="subheader_paper FWM">
                            Groupdecks:
                        </b>{" "}
                        is a study mode that allows users to simultaneously
                        study grouped cards together, while maintaining
                        individual card learning SRS values.
                        <br />
                        <br /> - <b class="subheader_paper FWM">Notes:</b> are
                        brief PDF-styled HTML pages that can be created to share
                        summaries, sound clips, and visual aids for educational
                        topics.
                        <br />
                        <br /> - <b class="subheader_paper FWM">
                            Formats:
                        </b>{" "}
                        pre-packaged HTML templates that users can create and
                        share for Decks and Notes.
                        <br /> With Formats, users will no longer need to worry
                        about creating custom flashcard formats for each deck.
                        <br />
                        <br /> -{" "}
                        <b class="subheader_paper FWM">
                            Online/Offline Yomichan for Desktop:
                        </b>{" "}
                        language learners will no longer depend on online
                        subtitle translation and vocab-card creation tools.
                        StudyChan will work offline on user's desktops, and it
                        will work outside of web-browsers for any text document
                        or subtitle.
                    </p>

                    <Divider direction="horizontal" />
                </Space>
            </Row>

            <BackTop style={{ position: "relative" }} />
        </div>
    );
};

export default Page_Bio;
