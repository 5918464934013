import * as actionTypes from "./actionTypes";

export const setTheme = (data) => {
    return {
        type: actionTypes.SET_THEME,
        data: data,
    };
};


export const setPage = (data) => {
    return {
        type: actionTypes.SET_PAGE,
        data: data,
    };
};