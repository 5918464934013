import React from "react";
import { Router } from "react-router-dom"; // BrowserRouter
import CustomLayout from "./containers/Layout";
import { createBrowserHistory } from "history";
import "./styles/style.less";

export const appHistory = createBrowserHistory();

function App(props) {
    return (
        <div className="App">
            <Router history={appHistory}>
                <CustomLayout {...props}></CustomLayout>
            </Router>
        </div>
    );
}

export default App;
