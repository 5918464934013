import React from "react";

import dev1 from "../media/_bio/dev1.png";
import front1 from "../media/_bio/front1.png";
import icon_cns from "../media/_bio/icon_cns.png";
import jeremey_wanamaker from "../media/_bio/jw.jpg";

import skill1 from "../media/_bio/skill1.jpg";
import skill2 from "../media/_bio/skill2.png";
import skill3 from "../media/_bio/skill3.png";
import skill4 from "../media/_bio/skill4.1.png";

import yaw_1 from "../media/_bio/exo_video.mp4";
import yaw_2 from "../media/_bio/exo_video_poster.png";
import yaw_3 from "../media/_bio/exo_cad.png";
import atc_overview from "../media/_bio/ATC_Overview.png";
import bio_icon from "../media/_bio/bio_icon.png";
import michael_crum from "../media/_bio/michael_crum.jpg";
import wesley_caudill from "../media/_bio/wesley_caudill.jpg";
import cgs_icon from "../media/_bio/cgs_icon.png";
import bruin3_mp4 from "../media/_bio/bruin3.mp4";
import bruin3_mp4_poster from "../media/_bio/bruin3_mp4_poster.jpg";
import bruin3_jpg from "../media/_bio/bruin3.jpg";
import background_small from "../media/_bio/aij_small.jpg";
import background_medium from "../media/_bio/aij_medium.jpg";
import background_large from "../media/_bio/aij_large.jpg";
import useWindowDimensions from "../redux/misc/windowDimensions";
import "../../node_modules/video-react/dist/video-react.css";
import VideoPlayer from "../components/VideoPlayer";

import {
    Avatar,
    Space,
    Typography,
    Col,
    Divider,
    Row,
    Image,
    BackTop,
    Popover,
} from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
const { Text } = Typography;

const content = (
    <div>
        <table>
            <th>Major</th>
            <th>Minor</th>
            <th>Percentile</th>

            {/* Conscientiousness */}
            <tr>
                <td>Conscientiousness</td>
                <td></td>
                <td style={{ textAlign: "center" }}>95</td>
            </tr>
            <tr>
                <td></td>
                <td>Orderliness</td>
                <td style={{ textAlign: "right" }}>95</td>
            </tr>
            <tr>
                <td></td>
                <td>Industriousness</td>
                <td style={{ textAlign: "right" }}>88</td>
            </tr>

            {/* Openness */}
            <tr>
                <td>Openness</td>
                <td></td>
                <td style={{ textAlign: "center" }}>94</td>
            </tr>
            <tr>
                <td></td>
                <td>Creativity</td>
                <td style={{ textAlign: "right" }}>90</td>
            </tr>
            <tr>
                <td></td>
                <td>Intellect</td>
                <td style={{ textAlign: "right" }}>89</td>
            </tr>

            {/* Agreeableness */}
            <tr>
                <td>Agreeableness</td>
                <td></td>
                <td style={{ textAlign: "center" }}>82</td>
            </tr>
            <tr>
                <td></td>
                <td>Politeness</td>
                <td style={{ textAlign: "right" }}>85</td>
            </tr>
            <tr>
                <td></td>
                <td>Compassion</td>
                <td style={{ textAlign: "right" }}>72</td>
            </tr>

            {/* Extraversion */}
            <tr>
                <td>Extraversion</td>
                <td></td>
                <td style={{ textAlign: "center" }}>31</td>
            </tr>
            <tr>
                <td></td>
                <td>Assertiveness</td>
                <td style={{ textAlign: "right" }}>63</td>
            </tr>
            <tr>
                <td></td>
                <td>Enthusiasm</td>
                <td style={{ textAlign: "right" }}>10</td>
            </tr>

            {/* Neuroticism */}
            <tr>
                <td>Neuroticism</td>
                <td></td>
                <td style={{ textAlign: "center" }}>53</td>
            </tr>
            <tr>
                <td></td>
                <td>Volatility</td>
                <td style={{ textAlign: "right" }}>53</td>
            </tr>
            <tr>
                <td></td>
                <td>Withdrawal</td>
                <td style={{ textAlign: "right" }}>52</td>
            </tr>
        </table>
    </div>
);

const Page_Bio = (props) => {
    const {
        scr_height,
        scr_width,
        // scr_SMALL,
        scr_MEDIUM,
        scr_LARGE,
    } = useWindowDimensions();

    var bio_pic = background_small;
    var body_width = scr_width - props.tab_width_small;
    var body_tab_offset = 0; //props.tab_width_small;
    var icon_size = scr_width / 20;
    var justify_body_text = "center";
    var parallax_avater_offset = "15vw";

    if (scr_MEDIUM) {
        bio_pic = background_medium;
        body_width = scr_width - props.tab_width_medium;
        body_tab_offset = props.tab_width_medium;
        icon_size = scr_width / 30;
        parallax_avater_offset = "";
    } else if (scr_LARGE) {
        bio_pic = background_large;
        body_width = scr_width - props.tab_width_large;
        body_tab_offset = props.tab_width_large;
        icon_size = scr_width / 30;
        // justify_body_text = "center";
        parallax_avater_offset = "";
    }

    return (
        <div
            style={{
                width: body_width,
            }}
            class={"Page_Bio"}
        >
            <div
                style={{
                    width: "100%",
                    height: scr_height * 0.88,
                    overflow: "visible",
                }}
            >
                <div
                    style={{
                        backgroundColor: "black",
                    }}
                >
                    <div
                        class="parallax animated fadeIn"
                        style={{
                            backgroundImage: `url(${bio_pic})`,
                            height: scr_height * 0.88,
                            width: body_width,
                            backgroundAttachment: "fixed",
                            backgroundPosition: "right top", // center
                            backgroundRepeat: "no-repeat",
                            backgroundSize: `${
                                body_width * 1.1
                            }px ${scr_height}px`,
                        }}
                    />
                </div>
                <Space
                    wrap={true}
                    align="start"
                    direction="vertical"
                    style={{
                        position: "absolute",
                        left: body_width * 0.2 + body_tab_offset,
                        top: scr_height * 0.28,
                        backgroundColor: "transparent",
                    }}
                >
                    <Row
                        justify="start"
                        style={{
                            backgroundColor: "transparent",
                        }}
                    >
                        <Avatar
                            src={bio_icon}
                            size={icon_size * 4}
                            style={{
                                backgroundColor: "transparent",
                                left: parallax_avater_offset,
                                maxWidth: icon_size * 3.5,
                            }}
                        />
                    </Row>

                    <p
                        class="parallax_text"
                        style={{
                            position: "relative",
                            opacity: 1,
                            textAlign: "left",
                            padding: "15px 15px 15px 30px",
                            borderRadius: "10px 50px 10px 50px",
                            maxWidth: "50vw",
                        }}
                    >
                        Methodical, articulate, and relentless to execute,
                        <br />
                        I love learning and am eager to join those who
                        <br />
                        are passionate about excellence and high-performance.
                        <div
                            style={{
                                position: "absolute",
                                right: "15px",
                                top: "15px",
                            }}
                        >
                            <Popover
                                content={content}
                                title="Big 5 Breakdown"
                                trigger="click"
                            >
                                <a
                                    href=" "
                                    class="header_body"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <QuestionCircleFilled
                                        style={{
                                            fontSize: `30px`,
                                        }}
                                        class="FWB"
                                    />
                                </a>
                            </Popover>
                        </div>
                    </p>
                </Space>
            </div>

            <Divider direction="horizontal" />

            <Space
                direction="vertical"
                size="small"
                style={{ width: body_width }}
            >
                <Row justify="space-around">
                    <Text class="body_paper FWB TSPPPP">Skills:</Text>
                </Row>

                <Row
                    justify="space-around"
                    className={"notranslate"}
                    style={{
                        textAlign: "center",
                        paddingRight: "10%",
                        paddingLeft: "10%",
                    }}
                >
                    <Col span={6} style={{ padding: body_width / 30 }}>
                        <Space size="large" direction="vertical">
                            <Text class="header_paper FWB">Dev Ops: </Text>
                            <Avatar size={icon_size} src={skill1} />
                            <Text class="body_paper">
                                AWS - Linode,
                                <br /> CD Pipelines,
                                <br /> GitLab - GitHub
                            </Text>
                        </Space>
                    </Col>

                    <Col span={6} style={{ padding: body_width / 30 }}>
                        <Space size="large" direction="vertical">
                            <Text class="header_paper FWB">Backend: </Text>
                            <Avatar size={icon_size} src={skill2} />
                            <Text class="body_paper">
                                Django - Python,
                                <br /> Express.js - Node.js,
                                <br /> PostgreSQL - MySQL,
                                <br /> 3rd. Party API's
                            </Text>
                        </Space>
                    </Col>

                    <Col span={6} style={{ padding: body_width / 30 }}>
                        <Space size="large" direction="vertical">
                            <Text class="header_paper FWB">Frontend: </Text>
                            <Avatar size={icon_size} src={skill3} />
                            <Text class="body_paper">
                                Vue.js - React.js,
                                <br /> JavaScript - Typscript,
                                <br /> React Native,
                                <br /> SCSS - LESS
                            </Text>
                        </Space>
                    </Col>

                    <Col span={6} style={{ padding: body_width / 30 }}>
                        <Space size="large" direction="vertical">
                            <Text class="header_paper FWB">Robotics:</Text>
                            <Avatar size={icon_size} src={skill4} />
                            <Text class="body_paper">
                                C / C++,
                                <br /> ROS,
                                <br /> Robotics - Hardware
                            </Text>
                        </Space>
                    </Col>
                </Row>
            </Space>

            <Row justify={justify_body_text}>
                <Space
                    direction="vertical"
                    size="large"
                    style={{
                        padding: "20px 20px 20px 0px",
                        maxWidth: 900,
                        width: body_width,
                    }}
                >
                    <Row justify="end">
                        <Space direction="vertical">
                            <p class="subheader_paper TAR FWB TSPP">
                                BS - Computer Engineering - 3.5 GPA
                            </p>
                            <p class="body_paper TAR">Graduatated 2021</p>
                        </Space>
                    </Row>

                    {/* <Row
                        justify="start"
                        style={{
                            width: "100%",
                            textAlign: "left",
                        }}
                    >
                        <Space direction="horizontal">
                            <p
                                class="header_paper_no_indent TAR FWB TSPP"
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                BS: Computer Engineering
                            </p>
                            <p
                                class="header_paper_no_indent FWM"
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                3.5 GPA
                            </p>
                            <p
                                class="header_paper_no_indent FWM"
                                style={{
                                    paddingLeft: "0px",
                                }}
                            >
                                - 2021
                            </p>
                        </Space>
                    </Row> */}

                    {/* SOFTWARE SECTION */}

                    <Row justify="center">
                        <Divider direction="horizontal" />
                        <Text class="body_paper FWB TSPPPP">
                            Software Engineering:
                        </Text>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Row>
                    <Row justify="space-between">
                        <Space>
                            <Text
                                class="header_paper FWB"
                                style={{ textAlign: "left" }}
                            >
                                SaaS MVP: Designer & Project Manager
                            </Text>

                            <a
                                href="https://www.complete.network"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Space align="center">
                                    <Text class="header_paper FWM">
                                        - Complete Network
                                    </Text>
                                    <Avatar size="large" src={icon_cns} />
                                </Space>
                            </a>

                            <Text
                                class="header_paper FWM"
                                style={{ paddingLeft: "10px" }}
                            >
                                2021-2023
                            </Text>
                        </Space>
                    </Row>

                    <Row justify="space-between" align="middle">
                        <Text
                            class="body_paper"
                            style={{
                                textAlign: "left",
                                position: "relative",
                                left: body_width / 20,
                            }}
                        >
                            When hired by Complete Network, I was handed 6 ppt.
                            slides, a deadline, and a mandate.
                            <br />
                            I was there to design internal software (and a
                            potential external start up MVP)
                            <br />I took full responsibility for the project's
                            initial development and first success.
                        </Text>

                        <Space size="large">
                            <div class="pointer mediaBackground">
                                <Image width={100} height={100} src={dev1} />

                                <Image width={100} height={100} src={front1} />
                            </div>
                        </Space>
                    </Row>

                    <Row justify="end">
                        <Space direction="vertical">
                            <p class="subheader_paper TAR FWB TSPP">
                                "Initiative is where Jon really shines.
                                <br />
                                He has strong internal motivation and goes the
                                extra mile.
                                <br />
                                He has good working habits that power him
                                <br />
                                to create and accomplish his own goals."
                            </p>
                            <p class="body_paper TAR">
                                <a
                                    href="https://www.linkedin.com/in/jeremywanamaker/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Jeremy Wanamaker{" "}
                                    <Avatar
                                        size={icon_size}
                                        src={jeremey_wanamaker}
                                    />
                                </a>
                            </p>
                        </Space>
                    </Row>

                    <Row justify="start" style={{ marginTop: "40px" }}>
                        <Space>
                            <Text
                                class="header_paper FWB"
                                style={{ textAlign: "left" }}
                            >
                                Temp. Data Automation Engineer
                            </Text>

                            <a
                                href="https://www.customglasssolutions.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Space align="center">
                                    <Text class="header_paper FWM">
                                        - Custom Glass Solutions LLC.
                                    </Text>
                                    <Avatar size="large" src={cgs_icon} />
                                </Space>
                            </a>

                            <Text
                                class="header_paper FWM"
                                style={{ paddingLeft: "10px" }}
                            >
                                2019-2020
                            </Text>
                        </Space>
                    </Row>

                    <Row justify="start" align="middle">
                        <Text
                            class="body_paper"
                            style={{
                                textAlign: "left",
                                position: "relative",
                                left: body_width / 20,
                            }}
                        >
                            Worked with an automotive windshield company
                            full-time for 8 months as an extended intern,
                            <br />
                            using ASP.Net and VB.Net to design and build
                            prototype utility software
                            <br />
                            to automate and simplify windshield parts' specs,
                            reporting, and production workflow.
                        </Text>
                    </Row>

                    <Row justify="end">
                        <Space direction="vertical">
                            <p class="subheader_paper TAR FWB TSPP">
                                "Quick to learn and execute, exceeded my
                                expectations."
                            </p>
                            <p class="body_paper TAR">
                                <a
                                    href="https://www.linkedin.com/in/wesley-caudill-491933201/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Wesley Caudill{" "}
                                    <Avatar
                                        size={icon_size}
                                        src={wesley_caudill}
                                    />
                                </a>
                            </p>
                        </Space>
                    </Row>

                    <Row justify="start" style={{ marginTop: "40px" }}>
                        <Space>
                            <Text
                                class="header_paper FWB"
                                style={{ textAlign: "left" }}
                            >
                                Dev Ops Team Lead
                            </Text>

                            <Text class="header_paper FWM">- 2020</Text>
                        </Space>
                    </Row>

                    <Row justify="space-between">
                        <Space
                            direction="vertical"
                            style={{ textAlign: "left" }}
                            size="large"
                        >
                            <Text
                                class="body_paper"
                                style={{
                                    textAlign: "left",
                                    position: "relative",
                                    left: body_width / 20,
                                }}
                            >
                                Used Django, AWS, and other Dev Ops tools to
                                design, orchestrate, and maintain
                                <br />a safety-critical management system to
                                operate and monitor airports' traffic.
                            </Text>
                        </Space>
                        <Space size="large">
                            <div class="pointer mediaBackground">
                                <Image
                                    width={100}
                                    height={100}
                                    src={atc_overview}
                                />
                            </div>
                        </Space>
                    </Row>

                    <Row justify="end">
                        <Space direction="vertical">
                            <p class="subheader_paper TAR FWB TSPP">
                                "Amazing effort, communication, and job
                                completion."
                            </p>
                            <p class="body_paper TAR">anonymous peer review</p>
                        </Space>
                    </Row>

                    {/* ROBOTICS SECTION */}

                    <Row justify="center">
                        <Divider direction="horizontal" />
                        <Text class="body_paper FWB TSPPPP">
                            Robotics & Embedded Systems:
                        </Text>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Row>

                    <Row justify="start">
                        <Space>
                            <Text
                                class="header_paper FWB"
                                style={{ textAlign: "left" }}
                            >
                                Engineering Capstone
                            </Text>

                            <Text class="header_paper FWM">
                                - Exoskeleton Joint:
                            </Text>
                        </Space>

                        <Text
                            class="header_paper FWM"
                            style={{ paddingLeft: "10px" }}
                        >
                            2021
                        </Text>
                    </Row>

                    <Row justify="space-between">
                        <Space
                            direction="vertical"
                            style={{ textAlign: "left" }}
                            size="large"
                        >
                            <Text
                                class="body_paper"
                                style={{
                                    position: "relative",
                                    left: body_width / 20,
                                }}
                            >
                                Used CAD, circuitry and a motor, an Arduino, and
                                a BLE app
                                <br />
                                to design, machine, assemble, and operate a new
                                prototype
                                <br />
                                joint for medical rehabilitation exoskeletons.
                            </Text>
                        </Space>

                        <Space size="large" className="mediaBackground">
                            <div class="pointer">
                                <VideoPlayer
                                    video={yaw_1}
                                    poster={yaw_2}
                                    default_width={100}
                                    default_height={100}
                                    active_width={300}
                                    active_height={400}
                                />
                            </div>
                            <div class="pointer">
                                <Image width={100} height={100} src={yaw_3} />
                            </div>
                        </Space>
                    </Row>

                    <Row justify="end">
                        <Space direction="vertical">
                            <p class="subheader_paper TAR FWB TSPP">
                                "Goes above and beyond, multiple all-nighters."
                            </p>
                            <p class="body_paper TAR">
                                <a
                                    href="https://www.linkedin.com/in/michael-crum-9b5745162/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Michael Crum{" "}
                                    <Avatar
                                        size={icon_size}
                                        src={michael_crum}
                                    />
                                </a>
                            </p>
                        </Space>
                    </Row>

                    <Row justify="start" style={{ marginTop: "40px" }}>
                        <Space>
                            <Text
                                class="header_paper FWB"
                                style={{ textAlign: "left" }}
                            >
                                Robotics Team Lead
                            </Text>

                            <Text class="header_paper FWM">- 2020</Text>
                        </Space>
                    </Row>

                    <Row justify="space-between">
                        <Space
                            direction="vertical"
                            style={{ textAlign: "left" }}
                            size="large"
                        >
                            <Text
                                class="body_paper"
                                style={{
                                    textAlign: "left",
                                    position: "relative",
                                    left: body_width / 20,
                                }}
                            >
                                Designed and implemented software for a
                                speedometer and parking brake for BJU’s
                                <br />
                                autonomous vehicle using the DoD's proprietary
                                software, RTK for ROS.
                                <br />
                                Reviewed and assisted creating the DoD’s ROS
                                documentation and training material.
                            </Text>
                        </Space>

                        <Space size="large" className=" mediaBackground">
                            <VideoPlayer
                                video={bruin3_mp4}
                                poster={bruin3_mp4_poster}
                                default_width={100}
                                default_height={100}
                                active_width={300}
                                active_height={400}
                            />
                            <div class="pointer">
                                <Image
                                    width={100}
                                    height={100}
                                    src={bruin3_jpg}
                                />
                            </div>
                        </Space>
                    </Row>

                    <Row justify="end">
                        <Space direction="vertical">
                            <p class="subheader_paper TAR FWB TSPP">
                                "He is working every time I walk past which is
                                extremely motivational."
                            </p>
                            <p class="subheader_paper TAR FWB TSPP">
                                "Dedicated to his work, I am amazed to see how
                                much he accomplished."
                            </p>
                            <p class="body_paper TAR">anonymous peer review</p>
                        </Space>
                    </Row>

                    <Divider direction="horizontal" />
                </Space>
            </Row>

            <BackTop style={{ position: "relative" }} />
        </div>
    );
};

export default Page_Bio;
