import React from "react";

import icon_aws from "../media/_project_sceince/icon_aws.png";
import icon_django from "../media/_project_sceince/icon_django.png";
import icon_ts from "../media/_project_sceince/icon_ts.png";
import icon_docker from "../media/_project_sceince/icon_docker.png";
import icon_gitlab from "../media/_project_sceince/icon_gitlab.png";
import icon_postgresql from "../media/_project_sceince/icon_postgresql.png";
import icon_vue from "../media/_project_sceince/icon_vue.png";

import dev1 from "../media/_project_sceince/dev1.png";
import dev2 from "../media/_project_sceince/dev2.png";
import dev3 from "../media/_project_sceince/dev3.png";
import dev4 from "../media/_project_sceince/dev4.png";

import front1 from "../media/_project_sceince/front1.png";
import front2 from "../media/_project_sceince/front2.png";
import front3 from "../media/_project_sceince/front3.png";
import front4 from "../media/_project_sceince/front4.png";

import back1 from "../media/_project_sceince/back1.png";
import back2 from "../media/_project_sceince/back2.png";
import back3 from "../media/_project_sceince/back3.4.png";
import back4 from "../media/_project_sceince/back5.png";
import back5 from "../media/_project_sceince/back6.png";
import back6 from "../media/_project_sceince/back7.png";
import back7 from "../media/_project_sceince/back8.png";
import back8 from "../media/_project_sceince/back9.png";

import background_small from "../media/_project_sceince/banner3.png";
import market from "../media/_project_sceince/market.jpg";
import business_plan from "../media/_project_sceince/business_plan.png";
import ps_moto from "../media/_project_sceince/ps_moto.png";
import useWindowDimensions from "../redux/misc/windowDimensions";
import "../../node_modules/video-react/dist/video-react.css";
import ImageCaption from "../components/Image_Caption";

import {
    Avatar,
    Space,
    Typography,
    Col,
    Divider,
    Row,
    Image,
    BackTop,
} from "antd";
const { Text } = Typography;

const Page_Bio = (props) => {
    const {
        scr_height,
        scr_width,
        // scr_SMALL,
        scr_MEDIUM,
        scr_LARGE,
    } = useWindowDimensions();

    var bio_pic = background_small;
    var body_width = scr_width - props.tab_width_small;
    var body_tab_offset = 0; //props.tab_width_small;
    var icon_size = scr_width / 20;
    var justify_body_text = "center";

    if (scr_MEDIUM) {
        body_width = scr_width - props.tab_width_medium;
        body_tab_offset = props.tab_width_medium;
        icon_size = scr_width / 30;
    } else if (scr_LARGE) {
        body_width = scr_width - props.tab_width_large;
        body_tab_offset = props.tab_width_large;
        icon_size = scr_width / 30;
    }

    return (
        <div
            style={{
                width: body_width,
            }}
            class={"Page_Bio"}
        >
            <div
                style={{
                    width: "100%",
                    height: scr_height * 0.88,
                    overflow: "visible",
                }}
            >
                <div>
                    <div
                        class="parallax animated fadeIn"
                        style={{
                            backgroundImage: `url(${bio_pic})`,
                            height: scr_height * 0.88,
                            width: body_width,
                            backgroundAttachment: "fixed",
                            backgroundPosition: "right top", // center
                            backgroundRepeat: "no-repeat",
                            backgroundSize: `${
                                body_width * 1.1
                            }px ${scr_height}px`,
                        }}
                    />
                </div>

                <Image
                    width={450}
                    height={180}
                    src={ps_moto}
                    style={{
                        position: "absolute",
                        left: body_width * 0.25 + body_tab_offset,
                        top: scr_height * -0.3,
                        backgroundColor: "transparent",
                        borderRadius: "15px",
                    }}
                />
            </div>

            <Divider direction="horizontal" />

            <Space
                direction="vertical"
                size="small"
                style={{ width: body_width }}
            >
                <Row justify="space-around">
                    <Text class="body_paper FWB TSPPPP">Sections:</Text>
                </Row>

                <Row
                    justify="space-around"
                    className={"notranslate"}
                    style={{
                        textAlign: "center",
                        paddingRight: "10%",
                        paddingLeft: "10%",
                    }}
                >
                    <Col span={6} style={{ padding: body_width / 30 }}>
                        <Space size="large" direction="vertical">
                            <Text class="header_paper FWB">I. Context</Text>
                            <Text class="body_paper">
                                Go-to-Market - Research
                            </Text>
                        </Space>
                    </Col>

                    <Col span={6} style={{ padding: body_width / 30 }}>
                        <Space size="large" direction="vertical">
                            <Text class="header_paper FWB">II. Dev Ops </Text>
                            <Text class="body_paper">
                                Platform - CD Pipeline
                            </Text>
                        </Space>
                    </Col>

                    <Col span={6} style={{ padding: body_width / 30 }}>
                        <Space size="large" direction="vertical">
                            <Text class="header_paper FWB">III. Backend </Text>
                            <Text class="body_paper">API's - Database</Text>
                        </Space>
                    </Col>

                    <Col span={6} style={{ padding: body_width / 30 }}>
                        <Space size="large" direction="vertical">
                            <Text class="header_paper FWB">IV. Frontend </Text>
                            <Text class="body_paper">UI/UX - Webapp</Text>
                        </Space>
                    </Col>
                </Row>
            </Space>

            <Row justify={justify_body_text}>
                <Space
                    direction="vertical"
                    size="large"
                    style={{
                        padding: "20px 20px 20px 0px",
                        maxWidth: 900,
                        width: body_width,
                    }}
                >
                    <Row justify="center">
                        <Divider direction="horizontal" />
                    </Row>

                    <Row
                        justify="space-between"
                        align="start"
                        direction="vertical"
                    >
                        <Space direction="vertical">
                            <Text
                                class="header_paper FWB"
                                style={{
                                    textAlign: "left",
                                    paddingLeft: "20px",
                                }}
                            >
                                I. Context
                            </Text>

                            <br />

                            <Text
                                class="body_paper"
                                style={{
                                    textAlign: "left",
                                    position: "relative",
                                    left: body_width / 20,
                                }}
                            >
                                When hired by Complete Network, I was handed 6
                                ppt. slides, a deadline, and a mandate.
                                <br />
                                <br />
                                I was there to design internal software (and a
                                potential external start up MVP)
                                <br />
                                that offers an automatable roadmap for IT MSP's
                                internal quoting and sales process.
                                <br />
                                <br />
                                I was able to refer to others in the department
                                for input and advice,
                                <br />
                                but I was to work entirely independently, and I
                                took full responsibility for the project.
                                <br />
                                <br />
                                <br />
                                <br />
                                Ultimately, the project acheived internal
                                adoption.
                                <br />
                                <br />
                                I authored and submitted a potential business
                                plan,
                                <br />
                                if they chose to solicite Project Science to
                                other IT MSP's.
                            </Text>
                        </Space>

                        <Space direction="vertical">
                            <ImageCaption
                                float="right"
                                padding="0px 0 0 20px"
                                width={"150px"}
                                image_url={market}
                                header="Competators (colored), "
                                br="true"
                                title="graphed over ICP budget range (black)."
                            />
                            <ImageCaption
                                float="right"
                                padding="25px 0 0 20px"
                                width={"200px"}
                                image_url={business_plan}
                                header="Equations 1:"
                                br="true"
                                title=" Uniform CoG's"
                            />
                        </Space>
                    </Row>

                    <Divider direction="horizontal" />

                    <Row
                        justify="space-between"
                        align="start"
                        direction="vertical"
                    >
                        <Space direction="vertical">
                            <Space>
                                <Text
                                    class="header_paper FWB"
                                    style={{
                                        textAlign: "left",
                                        paddingLeft: "20px",
                                        paddingRight: "30px",
                                    }}
                                >
                                    II. Dev Ops
                                </Text>

                                <Avatar size={icon_size} src={icon_aws} />
                                <Avatar size={icon_size} src={icon_docker} />
                                <Avatar size={icon_size} src={icon_gitlab} />
                            </Space>

                            <br />

                            <Text
                                class="body_paper"
                                style={{
                                    textAlign: "left",
                                    position: "relative",
                                    left: body_width / 20,
                                }}
                            >
                                Project Science was designed with the following:
                                <br />
                                <br />
                                - 2 actively managed codebases (beckend,
                                frontend)
                                <br />
                                - 3 passively managed sections (database,
                                Gitlab, Sonarqube)
                                <br />
                                - 3 operating platforms (local-dev-pc's, Gitlab)
                                <br />
                                - 3 environments (development, staging,
                                production)
                                <br />- 3 git repository branches (development,
                                staging, main_backup)
                                <br />
                                <br />
                                Below are documentation excerpts depicting some
                                of the core dev ops setup and features.
                            </Text>
                        </Space>
                    </Row>

                    <Space direction="horizontal">
                        <ImageCaption
                            float="right"
                            padding="0px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={dev1}
                            header="Documentation Excerpt:"
                            br="true"
                            title="Project and AWS Env."
                        />
                        <ImageCaption
                            float="right"
                            padding="0px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={dev2}
                            header="Documentation Excerpt:"
                            br="true"
                            title="Farley's Single Branch Pipeline"
                        />
                        <ImageCaption
                            float="right"
                            padding="0px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={dev3}
                            header="Documentation Excerpt:"
                            br="true"
                            title="Backend Pipelines"
                        />
                        <ImageCaption
                            float="right"
                            padding="0px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={dev4}
                            header="Documentation Excerpt:"
                            br="true"
                            title="Frontend Pipelines"
                        />
                    </Space>

                    <Divider direction="horizontal" />

                    <Row
                        justify="space-between"
                        align="start"
                        direction="vertical"
                    >
                        <Space direction="vertical">
                            <Space>
                                <Text
                                    class="header_paper FWB"
                                    style={{
                                        textAlign: "left",
                                        paddingLeft: "20px",
                                        paddingRight: "30px",
                                    }}
                                >
                                    III. Backend
                                </Text>

                                <Avatar size={icon_size} src={icon_django} />
                                <Avatar
                                    size={icon_size}
                                    src={icon_postgresql}
                                />
                            </Space>

                            <br />
                        </Space>
                    </Row>

                    <Text
                        class="body_paper"
                        style={{
                            textAlign: "center",
                            position: "relative",
                            left: body_width / 20,
                        }}
                    >
                        Below are some samples of the backend's ERD
                        Documentation,
                        <br />
                        as well as a custom serializer class which combines the
                        functionalities
                        <br />
                        of the pip modules WritableNestedSerializer and
                        RecursiveField
                        <br />
                        <br />
                        I also integrated Project Science with ConnectWise's
                        API.
                        <br />
                        They have a C# dev kit, but no Python help, so I started
                        creating my own utilities.
                    </Text>

                    <Space direction="horizontal">
                        <ImageCaption
                            float="right"
                            padding="0px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={back1}
                            header="Documentation Excerpt:"
                            br="true"
                            title="Backend ERD for DB"
                        />
                        <ImageCaption
                            float="right"
                            padding="0px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={back6}
                            header="Serializer Class Sample:"
                            br="true"
                            title="Surface-level Serializer Class"
                        />
                        <ImageCaption
                            float="right"
                            padding="0px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={back7}
                            header="Serializer Class Sample:"
                            br="true"
                            title="Inheriting Serializer Classes"
                        />
                        <ImageCaption
                            float="right"
                            padding="0px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={back8}
                            header="Serializer Class Sample:"
                            br="true"
                            title="Serializer Classes use case"
                        />
                    </Space>

                    <Space direction="horizontal">
                        <ImageCaption
                            float="right"
                            padding="5px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={back2}
                            header="CW Export Sample: "
                            br="true"
                            title="Exporting Quote to CW."
                        />
                        <ImageCaption
                            float="right"
                            padding="5px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={back3}
                            header="CW Export Sample: "
                            br="true"
                            title="Relationship Handling"
                        />
                        <ImageCaption
                            float="right"
                            padding="5px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={back5}
                            header="CW Export Sample: "
                            br="true"
                            title="Interface Entitity Class"
                        />
                        <ImageCaption
                            float="right"
                            padding="5px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={back4}
                            header="CW Export Sample: "
                            br="true"
                            title="Parent Interface Class"
                        />
                    </Space>

                    <Divider direction="horizontal" />

                    <Row
                        justify="space-between"
                        align="start"
                        direction="vertical"
                    >
                        <Space direction="vertical">
                            <Space>
                                <Text
                                    class="header_paper FWB"
                                    style={{
                                        textAlign: "left",
                                        paddingLeft: "20px",
                                        paddingRight: "30px",
                                    }}
                                >
                                    IV. Frontend
                                </Text>

                                <Avatar size={icon_size} src={icon_ts} />
                                <Avatar size={icon_size} src={icon_vue} />

                                <Text
                                    class="body_paper"
                                    style={{
                                        textAlign: "center",
                                        position: "relative",
                                        left: body_width / 20,
                                    }}
                                >
                                    Below are some samples of the more finished
                                    frontend GUI.
                                </Text>
                            </Space>

                            <br />
                        </Space>
                    </Row>

                    <Space direction="horizontal">
                        <ImageCaption
                            float="right"
                            padding="0px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={front1}
                            header="GUI Sample:"
                            br="true"
                            title="Login Screen"
                        />
                        <ImageCaption
                            float="right"
                            padding="0px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={front2}
                            header="GUI Sample:"
                            br="true"
                            title="Home Screen"
                        />
                        <ImageCaption
                            float="right"
                            padding="0px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={front3}
                            header="GUI Sample:"
                            br="true"
                            title="1-of-6 Main Quote Sections"
                        />
                        <ImageCaption
                            float="right"
                            padding="0px 0 0 20px"
                            width={"200px"}
                            height={"100px"}
                            image_url={front4}
                            header="GUI Sample:"
                            br="true"
                            title="Drag-n-Drop Quote Item Table"
                        />
                    </Space>

                    <Divider direction="horizontal" />
                </Space>
            </Row>

            <BackTop style={{ position: "relative" }} />
        </div>
    );
};

export default Page_Bio;
