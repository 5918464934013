import React, { useState } from "react";
import { Space } from "antd";
import ReactPlayer from "react-player/youtube";

const YtPlayer = (props) => {
    const [vid_width, setVid_width] = useState(props.default_width);
    const [vid_height, setVid_height] = useState(props.default_height);

    return (
        <div
            class="body_image"
            style={{
                float: `${props.float}`,
                padding: `${props.padding}`,
            }}
        >
            <Space
                wrap="true"
                direction="horizontal"
                class="faded_paper"
                style={{
                    width: `${vid_width}`,
                    height: `${vid_height}`,
                }}
            >
                <div>
                    {props.header ? <>{props.header}</> : null}
                    {props.br ? <br /> : null}
                    {props.title ? <>{props.title}</> : null}
                </div>
            </Space>
            <ReactPlayer
                controls
                width={vid_width}
                height={vid_height}
                light
                onPlay={() => {
                    setVid_width(props.active_width);
                    setVid_height(props.active_height);
                }}
                onBuffer={() => {
                    setVid_width(props.active_width);
                    setVid_height(props.active_height);
                }}
                onSeek={() => {
                    setVid_width(props.active_width);
                    setVid_height(props.active_height);
                }}
                onPause={() => {
                    setVid_width(props.default_width);
                    setVid_height(props.default_height);
                }}
                onEnded={() => {
                    setVid_width(props.default_width);
                    setVid_height(props.default_height);
                }}
                url={props.url}
            />
        </div>
    );
};
export default YtPlayer;
