import React from 'react';
import { Typography } from "antd"

const Image_Caption = (props) => {

    return (
        <>
            {props.br ? (
                <>
                    <br />
                    <Typography.Text class="background" style={{ fontSize: "1.5em", color: "transparent" }}>
                        .....
                    </Typography.Text>
                </>
            ) : (
                    <Typography.Text class="background" style={{ color: "transparent" }}>
                        ........
                    </Typography.Text>
                )}
        </>
    )
};
export default Image_Caption;