import React, { useState, useEffect } from "react";

import exo1 from "../media/_exo/exo1.png";
import exo2 from "../media/_exo/exo2.png";
import exo3 from "../media/_exo/exo3.jpg";
import exo4 from "../media/_exo/exo4.png";
import exo5 from "../media/_exo/exo5.png";
import exo6 from "../media/_exo/exo6.mp4";
import exo7 from "../media/_exo/exo7.mp4";
import exo8 from "../media/_exo/exo8.jpg";
import exo9 from "../media/_exo/exo9.png";

import exo6_1 from "../media/_exo/exo6.1.png";
import exo7_1 from "../media/_exo/exo7.1.png";

import joint_loc from "../media/_exo/joint_location.png";
import background_small from "../media/_exo/hal_4.jpg";
import background_medium from "../media/_exo/hal_4.jpg";
import background_large from "../media/_exo/hal_4.jpg";
import plans_goodenough_image from "../media/_exo/goodenough.jpeg";
import useWindowDimensions from "../redux/misc/windowDimensions";
import "../../node_modules/video-react/dist/video-react.css";
import ImageCaption from "../components/Image_Caption";
import VideoPlayer from "../components/VideoPlayer";
import YtPlayer from "../components/YtPlayer";
import PIndent from "../components/P_Indent";

import { Space, Collapse, Divider, Row, Anchor, BackTop } from "antd";
const { Link } = Anchor;
const { Panel } = Collapse;

const video_intro_SARCOS = "https://youtu.be/W5P4i-aytws?t=24";
const video_intro_REWALK = "https://youtu.be/gGTYXxc7aVA?t=43";
const video_intro_HAL = "https://youtu.be/S3VSvoQc05w";

const plans_goodenough_link =
    "https://thedriven.io/2020/04/06/li-ion-co-inventor-patents-glass-battery-that-could-upturn-auto-industry/";
const plans_samsung_link =
    "https://www.nature.com/articles/s41560-020-0575-z.epdf";
const plans_neuralink = "https://youtu.be/KsX-7hS94Yo?t=74";
const plans_HAL = "https://youtu.be/UzQzMoJnpZI?t=409";

const issue_switzerland = "https://youtu.be/dckZI5aE-Ug?t=45";
const issue_REWALK = "https://youtu.be/Vy9wqQ8keQs?t=185";

const Page_Bio = (props) => {
    const {
        scr_height,
        scr_width,
        // scr_SMALL,
        scr_MEDIUM,
        scr_LARGE,
    } = useWindowDimensions();

    // const active_video_dim = {
    //     height: 400,
    //     width: 300,
    // };
    // const default_video_dim = {
    //     height: 100,
    //     width: 100,
    // };

    // const [video_active, setVideo_active] = useState(false);
    // const [video_dim, setVideo_dim] = useState(default_video_dim);

    // function toggle_video_active() {
    //     if (!video_active) {
    //         setVideo_active(true);
    //         setVideo_dim(active_video_dim);
    //     } else {
    //         setVideo_dim(default_video_dim);
    //         setVideo_active(false);
    //     }
    // }

    var bio_pic = background_small;
    var body_width = scr_width - props.tab_width_small;
    // var body_tab_offset = 0; //props.tab_width_small;
    // var bio_quote_width = 200;
    var justify_body_text = "start";
    // var body_height = scr_height * 0.9;

    if (scr_MEDIUM) {
        bio_pic = background_medium;
        body_width = scr_width - props.tab_width_medium;
        // body_tab_offset = props.tab_width_medium;
        // bio_quote_width = 550;
    } else if (scr_LARGE) {
        bio_pic = background_large;
        body_width = scr_width - props.tab_width_large;
        // body_tab_offset = props.tab_width_large;
        // bio_quote_width = 700;
        justify_body_text = "center";
    }

    const [targetOffset, setTargetOffset] = useState(undefined);
    useEffect(() => {
        setTargetOffset(window.innerHeight / 2);
    }, []);

    return (
        <div
            style={{
                width: body_width,
            }}
            class={"Page_Exo"}
        >
            {/* <
            isFixed={true}
            isCentered={false}
            opacity={0.1}
            blur={0}
            color="black"
            transitionDuration="1500"
            // imageSrc={bio_pic}
            style={{
                width: "100%",
                height: scr_height * 0.88,
                overflow: "visible"
            }}
        > */}
            <div
                isFixed={true}
                isCentered={false}
                opacity={0.1}
                blur={0}
                color="black"
                transitionDuration="1500"
                // imageSrc={bio_pic}
                style={{
                    width: "100%",
                    height: scr_height * 0.88,
                    overflow: "visible",
                }}
            >
                {/* {scr_LARGE ? ( */}
                <div
                    style={{
                        backgroundColor: "black",
                    }}
                >
                    <div
                        class="parallax animated fadeIn"
                        style={{
                            backgroundImage: `url(${bio_pic})`,
                            height: scr_height * 0.88,
                            width: body_width,
                            backgroundAttachment: "fixed",
                            backgroundPosition: "right top",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: `${
                                body_width * 1.1
                            }px ${scr_height}px`,
                        }}
                    />
                </div>
                {/* ) : (
                        <div style={{
                            backgroundColor: "black"
                        }}>
                            <div
                                class="parallax animated fadeIn"
                                style={{
                                    backgroundImage: `url(${bio_pic})`,
                                    height: scr_height * 0.95,
                                    width: body_width,
                                    backgroundAttachment: "fixed",
                                    backgroundPosition: "center", //"right top"
                                    backgroundRepeat: "no-repeat",
                                    // backgroundSize: `${body_width}px ${scr_height * 0.95}px`,
                                }}
                            />
                        </div>
                    )} */}
                <Space
                    wrap={true}
                    align="start"
                    direction="vertical"
                    style={{
                        position: "absolute",
                        right: body_width * 0.25,
                        top: scr_height * 0.55,
                    }}
                >
                    <p
                        class="parallax_text"
                        style={{
                            position: "relative",
                            opacity: 1,
                            textAlign: "left",
                            padding: "15px 15px 15px 30px",
                            borderRadius: "10px 50px 10px 50px",
                        }}
                    >
                        Innovation needs action.
                        <br />
                        Make an agile matured plan,
                        <br />
                        then pursute it relentlessly.
                    </p>
                </Space>
            </div>

            <Divider direction="horizontal" />

            <p class="header_paper TSPPPP TI0 TAC" style={{ width: "100%" }}>
                {" "}
                Exoskeletons: Robo Walking Sticks from the Future{" "}
            </p>

            <Row justify="end">
                <Anchor
                    targetOffset={targetOffset}
                    style={{
                        padding: 8,
                        textAlign: "start",
                        position: "relative",
                        right: 10,
                        width: "260px",
                    }}
                >
                    <Collapse bordered={true} defaultActiveKey={["1"]}>
                        <Panel header="Quick Link Table: " key="1" extra={""}>
                            <Space size="middle" direction="vertical">
                                <Link
                                    href="#I_"
                                    title="I. What are Exoskeletons?"
                                />
                                <Link
                                    href="#Ia"
                                    title={
                                        <>
                                            {" "}
                                            <PIndent />
                                            <PIndent /> a. Current Tech.{" "}
                                        </>
                                    }
                                />
                                <Link
                                    href="#Ib"
                                    title={
                                        <>
                                            {" "}
                                            <PIndent />
                                            <PIndent /> b. Future Plans{" "}
                                        </>
                                    }
                                />
                                <Link
                                    href="#Ic"
                                    title={
                                        <>
                                            {" "}
                                            <PIndent />
                                            <PIndent /> c. Current Obstacles{" "}
                                        </>
                                    }
                                />
                                <Link
                                    href="#II_"
                                    title="II. Proposed Joint Design"
                                />
                                <Link
                                    href="#IIa"
                                    title={
                                        <>
                                            {" "}
                                            <PIndent />
                                            <PIndent /> a. Mechanical{" "}
                                        </>
                                    }
                                />
                                <Link
                                    href="#IIb"
                                    title={
                                        <>
                                            {" "}
                                            <PIndent />
                                            <PIndent /> b. Other Components{" "}
                                        </>
                                    }
                                />
                                <Link
                                    href="#III_"
                                    title="III. Pragmatic Joint Prototype"
                                />
                                <Link
                                    href="#IIIa"
                                    title={
                                        <>
                                            {" "}
                                            <PIndent />
                                            <PIndent /> a. Mechanical{" "}
                                        </>
                                    }
                                />
                                <Link
                                    href="#IIIb"
                                    title={
                                        <>
                                            {" "}
                                            <PIndent />
                                            <PIndent /> b. Electrical{" "}
                                        </>
                                    }
                                />
                                <Link
                                    href="#IIIc"
                                    title={
                                        <>
                                            {" "}
                                            <PIndent />
                                            <PIndent /> c. Results & Demo{" "}
                                        </>
                                    }
                                />
                                <Link href="#IV_" title="IV. What Now?" />
                                {/* <Link href="#REF" title="References" /> */}
                            </Space>
                        </Panel>
                    </Collapse>
                </Anchor>
            </Row>

            <Row justify={justify_body_text}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        padding: "20px 20px 20px 0px",
                        maxWidth: 900,
                        width: body_width,
                    }}
                >
                    <p id="I_" class="header_paper TSPPP FWM">
                        I. What are Exoskeletons?
                        <Divider direction="horizontal" />
                    </p>

                    <p id="Ia" class="header_paper TSPP FWM TI0">
                        a. Current Tech.
                    </p>
                    <p class="body_paper">
                        <Space
                            direction="vertical"
                            size="large"
                            style={{
                                float: "right",
                                padding: "0px 0px 0px 20px",
                            }}
                        >
                            <YtPlayer
                                float="right"
                                padding="0px 0px 0px 20px"
                                url={video_intro_SARCOS}
                                default_width={125}
                                default_height={125}
                                active_width={900}
                                active_height={506}
                                // header="Video 1:"
                                title=" SARCOS's Exo"
                            />
                            <YtPlayer
                                float="right"
                                padding="0px 0px 0px 20px"
                                url={video_intro_REWALK}
                                default_width={125}
                                default_height={125}
                                active_width={900}
                                active_height={506}
                                // header="Video 1:"
                                title=" ReWalk's Exo"
                            />
                            <YtPlayer
                                float="right"
                                padding="0px 0px 0px 20px"
                                url={video_intro_HAL}
                                default_width={125}
                                default_height={125}
                                active_width={900}
                                active_height={506}
                                // header="Video 1:"
                                title=" Cyberdyne's Exo"
                            />
                        </Space>
                        <PIndent />
                        Exoskeletons are essentially motorized metal rods that
                        hug to the human physiology to help the wearer move
                        about with extra support and strength. Many sectors like
                        military, industry, and medical rehabilitaion are
                        developing exoskeleton models for their respective
                        needs. 3 of the most exciting models are America's
                        Guardian Xo by SARCOS, Israel's ReWalk, and Japan's HAL
                        by Cyberdyne.
                        <PIndent br={true} />
                        <b class="highlight_body_paper">
                            SARCOS's Guardian suit
                        </b>{" "}
                        was initially funded by the DoD and is intended for
                        industrial use. Though bulky, this suit's users can
                        effortlessly lift and carry 200 pounds while only
                        feeling one tenth the weight. Amazingly enough, while
                        moving around under such a load, the suit recently
                        consumes well under 500w, which is merely what a budget
                        gaming PC power supply is rated to use!
                        <PIndent br={true} />
                        <b class="highlight_body_paper">The ReWalk suit</b>{" "}
                        allows some paraplegics to walk by using pre-programed
                        movement cycles. Users have a smart watch to control
                        movement modes like walking and stair climbing, and they
                        shift their body weight from side to side to active the
                        movements.
                        <PIndent br={true} />
                        <b class="highlight_body_paper">
                            Cyberdyne's HAL suit
                        </b>{" "}
                        is similar to the ReWalk, but it also targets not only
                        paraplegic movement but also paraplegic rehabilitation
                        as well. It reads the neuron signals sent from the
                        user's brain to their limbs and directs the exoskeleton
                        to move in tandem with the user's thoughts. This
                        innovation not only allows the suit to be compatible for
                        both abled and disabled, but it also has shown to help
                        paraplegics restimulate their atrophied limbs and regain
                        some independent movement.
                        <PIndent br={true} />
                        These 3 exoskeleton models are world leaders amungst
                        their peers, and they highlight the main goals that
                        developers have for their suits.
                    </p>

                    <p id="Ib" class="header_paper TSPP FWM TI0">
                        b. Future Plans.
                    </p>
                    <p class="body_paper">
                        <Space
                            direction="vertical"
                            size="large"
                            style={{
                                float: "right",
                                padding: "0px 0px 0px 20px",
                            }}
                        >
                            <YtPlayer
                                float="right"
                                padding="0px 0px 0px 20px"
                                url={plans_HAL}
                                default_width={125}
                                default_height={125}
                                active_width={900}
                                active_height={506}
                                // header="Video 1:"
                                title=" Cyberdyne's Exo"
                            />
                            <ImageCaption
                                float="right"
                                padding="0 0 0 0"
                                width={"125px"}
                                image_url={plans_goodenough_image}
                                header={
                                    <a
                                        href={plans_goodenough_link}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Glass Batteries
                                    </a>
                                }
                                br={true}
                                title={
                                    <a
                                        href={plans_samsung_link}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Graphene Batteries
                                    </a>
                                }
                            />
                            <YtPlayer
                                float="right"
                                padding="0px 0px 0px 20px"
                                url={plans_neuralink}
                                default_width={125}
                                default_height={125}
                                active_width={900}
                                active_height={506}
                                // header="Video 1:"
                                title=" Neuralink Device"
                            />
                        </Space>
                        <PIndent />
                        In the near future, these exoskeletons will continue to
                        be refined into a matured product, and they will exit
                        exclusively private markets to become available to
                        public consumers. SARCOS hopes to extend their suit's
                        strength and power efficiency and then mass produce
                        their suit for heavy machinery environments, starting
                        with airport companies.
                        <PIndent br={true} />
                        Cyberdyne is currently selling{" "}
                        <b class="highlight_body_paper">
                            cut-down versions of HAL for public consumers
                        </b>
                        , but they hope to eventually develop a fully-featured
                        HAL available for private use without physical therapist
                        guidance.
                        <PIndent br={true} />
                        Other contemporary budding technologies promise to
                        advance the exoskeletons' capabilities and help push
                        them into open production.{" "}
                        <b class="highlight_body_paper">
                            Goodenough glass batteries
                        </b>{" "}
                        and{" "}
                        <b class="highlight_body_paper">
                            Samsung's graphene batteries
                        </b>{" "}
                        promise 3x more battery capacity, 2x faster charging
                        rates, and 2x longer lifespan for current lithium
                        batteries. These batteries will allow for stronger suits
                        for industry and all-day battery life for non-industrial
                        wearers.
                        <PIndent br={true} />
                        <b class="highlight_body_paper">
                            Neuralink implants
                        </b>{" "}
                        have to potential to revolutionize how implant
                        paraplegics can function and control electronics,
                        especially HAL-type suits.
                        <PIndent br={true} />
                        Once exoskeletons enter mass production, their costs
                        will exponentially decline and future innovations will
                        be better supported and motivated by both the producers
                        and customers.
                    </p>

                    <p id="Ic" class="header_paper TSPP FWM TI0">
                        c. Current Roadblock: Mobility
                    </p>
                    <p class="body_paper">
                        <Space
                            direction="vertical"
                            size="large"
                            style={{
                                float: "right",
                                padding: "0px 0px 0px 20px",
                            }}
                        >
                            <YtPlayer
                                float="right"
                                padding="0px 0px 0px 20px"
                                url={issue_REWALK}
                                default_width={125}
                                default_height={125}
                                active_width={900}
                                active_height={506}
                                // header="Video 1:"
                                title="ReWalk Rotation"
                            />
                            <YtPlayer
                                float="right"
                                padding="0px 0px 0px 20px"
                                url={issue_switzerland}
                                default_width={125}
                                default_height={125}
                                active_width={900}
                                active_height={506}
                                // header="Video 1:"
                                title="Swedish Frame"
                            />
                        </Space>
                        <PIndent />
                        Upcoming developements are very promising for current
                        exoskeletons, yet they still have one hamstringing
                        limitation: mobility. Of every exoskeleton available
                        worldwide today,{" "}
                        <b class="highlight_body_paper">
                            none are able to execute motorized sideways rotation
                            for users to turn around corners in a convenient
                            formfactor.
                        </b>
                        <PIndent br={true} />
                        Industrial exoskeletons design resilient yet bulky hip
                        joints, and many medical models sidestep the issue
                        entirely by{" "}
                        <b class="highlight_body_paper">
                            requiring a physical therapist
                        </b>{" "}
                        on hand to guard and help rotate the paraplegic user.
                        These solutions are appropriate for their respective
                        environments, but in order to truly become viable and
                        readily marketable for unassisted individual use, a
                        low-profile joint that can turn 90 degrees in one fluid
                        motion must eventually be developed.
                        <PIndent br={true} />A swedish university is prototyping
                        such a physiologically conforming frame, but it is too{" "}
                        <b class="highlight_body_paper">
                            {" "}
                            bulky and difficult to motorize
                        </b>{" "}
                        to be effective as a consumer product yet.
                        <PIndent br={true} />
                        Medical exoskeletons themselves are still handicaped. If
                        they are to effectively break the private/public market
                        barrier, they need at least one primitive, yet viable,
                        joint design that can enable 90 degree rotation for
                        users in a small form factor.
                    </p>

                    <p id="II_" class="header_paper TSPPP FWM">
                        II. Proposed Joint Design
                        <Divider direction="horizontal" />
                    </p>

                    <p id="IIa" class="header_paper TSPP FWM TI0">
                        a. Mechanical
                    </p>
                    <p class="body_paper">
                        <Space
                            direction="horizontal"
                            size="large"
                            style={{
                                float: "right",
                                padding: "0px 0px 0px 20px",
                            }}
                        >
                            <ImageCaption
                                float="right"
                                padding="0 0 0 0"
                                width={"125px"}
                                image_url={joint_loc}
                                header={"Proposed Joint"}
                                br={true}
                                title={"Location Outlined in Red"}
                            />
                        </Space>
                        <PIndent />
                        Instead of targeting the full range of motion at the hip
                        joint, in order to rotate the user 90 degrees in one
                        fluid motion,{" "}
                        <b class="highlight_body_paper">
                            {" "}
                            each leg needs only 45 degrees of rotationary
                            movement each. If both actuate together, a full 90
                            can be achieved.
                        </b>{" "}
                        When humans rotate on their legs, their whole thigh and
                        posterior region flexes and most of their entire leg
                        shifts to accommodate the motion. However, the thigh
                        muscles are the key force generators, and the
                        lower-thigh region seems to experience the highest
                        torsion forces.
                        <PIndent br={true} />
                        Therefore,{" "}
                        <b class="highlight_body_paper">
                            {" "}
                            it is proposed that a joint be placed at the
                            lower-thigh, just above the knee, and that this
                            joint excert the 45 degree rotation force.
                        </b>{" "}
                        This joint would connect directly to the upper and lower
                        metal frame-bars, and it would actuate along a T-slot
                        and ring gear pinion system. The prexisting straps
                        attached just above and below the knee will provide a
                        sure grip to apply the required torsion to the upper and
                        lower limb, depending on which limbic section is
                        required for a respective outwards or inwards pivoting
                        motion.
                    </p>

                    <p id="IIb" class="header_paper TSPP FWM TI0">
                        b. Other Components
                    </p>
                    <p class="body_paper">
                        <Space
                            direction="vertical"
                            size="large"
                            style={{
                                float: "right",
                                padding: "0px 0px 0px 20px",
                            }}
                        >
                            <ImageCaption
                                float="right"
                                padding="0 0 0 0"
                                width={"125px"}
                                image_url={exo1}
                                header={"Proposed Joint"}
                                br={true}
                                title={"CAD Model"}
                            />
                        </Space>
                        <PIndent />
                        Having the new mechanical joint preliminarily designed,
                        the other electrical and computer components fall into
                        place. This additional joint can be conveniently placed
                        into the empty space just above HAL's knee joint. The
                        electrical wiring and control are not categorically new
                        for the suit and can be relatively readily integrated
                        into the suit's preexisting schema.
                        <PIndent br={true} />
                        Only 2 unknown factors remain do address: mapping out
                        the nerve signals required to consistiently activate the
                        suit's rotation, and testing the joint's effectiveness
                        in reliably rotating the user. Self-evidently, the joint
                        must first show that it can consistiently rotate the
                        user at the lower-thigh before investigating user
                        operation or integration into a test suit.{" "}
                        <b class="highlight_body_paper">
                            {" "}
                            Section III. will cover how a modified version of
                            this design was constructed and the resulting
                            effectiveness of the design.
                        </b>{" "}
                    </p>

                    <p id="III_" class="header_paper TSPP FWM">
                        III. Pragmatic Joint Prototype
                        <Divider direction="horizontal" />
                    </p>

                    <p id="IIIa" class="header_paper TSPP FWM TI0">
                        a. Mechanical
                    </p>
                    <p class="body_paper">
                        <Space
                            direction="horizontal"
                            size="large"
                            style={{
                                float: "right",
                                padding: "0px 0px 0px 20px",
                            }}
                        >
                            <ImageCaption
                                float="right"
                                padding="0 0 0 0"
                                width={"125px"}
                                image_url={exo2}
                                header={"CAD Model"}
                            />
                            <ImageCaption
                                float="right"
                                padding="0 0 0 0"
                                width={"125px"}
                                image_url={exo3}
                                header={"Physical Model"}
                            />
                        </Space>
                        <PIndent />
                        The ideal joint design implements low form factor
                        pancake motors, solid milled aluminum blocks, and a
                        custom ring gear and pinion. Unless they are mass
                        produced, these parts are an order of magnitude too
                        expensive for personal preliminary prototyping. Instead,
                        for this proof of concept, the joint will be 3D printed
                        out of PLA, rather than aluminum metal. A curved rack
                        and pinion will be used along the side, instead of a
                        curved track being placed above. To maximize simplicity,
                        the secondary security latch will be removed, and the
                        primary locking solenoid pin will be placed ontop the
                        joint. Finally, a vehicle windshield wiper motor will be
                        placed overhead the pinion and rest on a metal tray
                        platform which will be afixed to the upper-leg frame.
                        <PIndent br={true} />{" "}
                        <b class="highlight_body_paper">
                            {" "}
                            This crude design will still have the core
                            functionality of the ideal design from II, and the
                            utility of rotating at the lower thigh versus
                            rotating at the hip socket can be analyzed.
                        </b>{" "}
                    </p>

                    <p id="IIIb" class="header_paper TSPP FWM TI0">
                        b. Electrical
                    </p>
                    <p class="body_paper">
                        <Space
                            direction="horizontal"
                            size="large"
                            style={{
                                float: "right",
                                padding: "0px 0px 0px 20px",
                            }}
                        >
                            <ImageCaption
                                float="right"
                                padding="0 0 0 0"
                                width={"125px"}
                                image_url={exo4}
                                header={"Electrical Component Layout"}
                            />
                            <ImageCaption
                                float="right"
                                padding="0 0 0 0"
                                width={"125px"}
                                image_url={exo5}
                                header={"Arduino Controller Board Schematic"}
                            />
                        </Space>
                        <PIndent />
                        The pragmatic prototype consists of a custom made PCB
                        board, a solenoid, a control foot pedal, and a pair of
                        relays and limit switches. As shown in the accompanying
                        electrical component layout diagram, the Arduino PCB
                        board recieves input commands from the attached foot
                        pedal and controls the solenoid, as well as the motor's
                        power through the relays and limit switches.
                        <PIndent br={true} />
                        One relay acts as the motor's power switch, and the
                        other relay is configured as an H-bridge for the motor's
                        forwards/reverse movements. THe limit switches guarantee
                        that the motor will stop as at the intended end-points
                        of actuation.
                    </p>

                    <p id="IIIc" class="header_paper TSPP FWM TI0">
                        c. Results & Demo
                    </p>
                    <p class="body_paper">
                        <Space
                            direction="horizontal"
                            size="large"
                            style={{
                                float: "right",
                                padding: "0px 0px 0px 20px",
                            }}
                        >
                            <VideoPlayer
                                float="right"
                                padding="0px 0px 0px 20px"
                                video={exo6}
                                poster={exo6_1}
                                default_width={125}
                                default_height={125}
                                active_width={900}
                                active_height={506}
                                // header="Video 1:"
                                title="Performance Test"
                            />
                            <VideoPlayer
                                float="right"
                                padding="0px 0px 0px 20px"
                                video={exo7}
                                poster={exo7_1}
                                default_width={125}
                                default_height={125}
                                active_width={900}
                                active_height={506}
                                // header="Video 1:"
                                title="Performance Demo"
                            />
                        </Space>
                        <PIndent />
                        As shown in the performance test and demo videos, the
                        resulting pragmatic prototype functions well and is a
                        successful proof-of-concept for this small yaw rotation
                        joint for exoskeletons. When the powered joint is paired
                        with a shoe mount to holster the user's foot, the device
                        reliably rotates the user 45-degrees.{" "}
                        <b class="highlight_body_paper">
                            {" "}
                            This prototype confirms that compact powered yaw
                            rotation is possible for paraplegic exoskeletons.
                        </b>{" "}
                        <PIndent br={true} />
                        If two of these joints could be implemented into both
                        legs of a current exoskeleton model, then the user would
                        be able to quickly, reliably, and efficiently rotate
                        around corners without assistance from others.
                        Additionally, users could also pair the rotating
                        movement with forward/backward and step up/sit down
                        movements to greatly expand the exoskeletons range of
                        movements. This general yaw joint design can greatly
                        enhance medical exoskeletons for both able bodied and
                        paraplegic users in the near future.
                    </p>

                    <p id="IV_" class="header_paper TSPP FWM">
                        IV. What Now?
                        <Divider direction="horizontal" />
                    </p>
                    <p class="body_paper">
                        <Space
                            direction="vertical"
                            size="large"
                            style={{
                                float: "right",
                                padding: "0px 0px 0px 20px",
                            }}
                        >
                            <ImageCaption
                                float="right"
                                padding="0 0 0 0"
                                width={"125px"}
                                image_url={exo8}
                                header={"Exoskeleton Company Cyberdyne:"}
                                br={true}
                                title={"in Tsukuba, Japan"}
                            />
                            <ImageCaption
                                float="right"
                                padding="0 0 0 0"
                                width={"125px"}
                                image_url={exo9}
                                header={"Yoshiyuki Sankai:"}
                                br={true}
                                title={"Founder of Cyberdyne"}
                            />
                        </Space>
                        <PIndent />
                        Before yaw rotation joints can be officially tested for
                        commercial exoskeletons, this yaw rotation joint design
                        needs two things. First, a redeisgned low form factor
                        solid aluminum joint and pancake motor system needs to
                        be implemented. Second, a natural way of controlling the
                        joint needs to be developed. If a user is going to
                        effectively use exoskeletons' quickly growing range of
                        movements, simple control buttons and pressure sensors
                        will not be sufficient.{" "}
                        <b class="highlight_body_paper">
                            {" "}
                            Control sytems like Japanese Cyberdyne's HAL suit
                            are best suited to take advantage of this new
                            joint's capabilities.
                        </b>{" "}
                        <PIndent br={true} />{" "}
                        <b class="highlight_body_paper">
                            {" "}
                            Over the next 5 years, Cyberdyne's primary goal is
                            to adapt products for the consumer market to expand
                            beyond the institutional medical rehabilitation
                            market.
                        </b>{" "}
                        Additionally, Cyberdyne's founder, Yoshiyuki Sankai is a
                        lead professor and researcher at Tsukuba University, and
                        he has close official relationships to the university's
                        research hospital. Because of Sankai's work and social
                        relations in the area, a tightly knit research community
                        has been created that is centered around the development
                        and furtherance of exoskeletons. Proposing this joint
                        design to Tsukuba University and their researchers is
                        the most efficient way to refine the design for use in
                        contemporary exoskeletons. Once refined, the design can
                        readily go through test trials by the hospital and be
                        validated for mass production by Sankai's company
                        Cyberdyne.{" "}
                        <b class="highlight_body_paper">
                            {" "}
                            This route seems to be the optimal method for
                            implementing this general yaw joint design into
                            current commercial exoskeltons today.
                        </b>{" "}
                        This joint's implementation is the next small step for
                        maturing the huge societal role that exoskeltons will
                        play in the near future for everyone.
                    </p>
                </Space>
            </Row>

            <BackTop style={{ position: "relative" }} />
        </div>
    );
};

export default Page_Bio;
