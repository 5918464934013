import { useState, useEffect } from "react";


const MED_SCR_WIDTH = 900;
const LRG_SCR_WIDTH = 1550;


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    // console.log("SCREEN SPECS: ", width, height)

    return {
        scr_width: width,
        scr_height: height,
        scr_SMALL: MED_SCR_WIDTH >= width ? true : false,
        scr_MEDIUM: (LRG_SCR_WIDTH > width && width > MED_SCR_WIDTH) ? true : false,
        scr_LARGE: width >= LRG_SCR_WIDTH ? true : false,
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
